import React from 'react'
import {
  dateString,
  formattedDuration,
  niceBlockedMessage,
  keyScales,
  formatStat,
} from '../../shared'
import AdminBeatListen from './AdminBeatListen'
import AdminEditItemLink from './AdminEditItemLink'
import UserLink from './UserLink'
import { Cell } from '../../components'

const AdminBeatCell = ({beat, noline, children}) => {
  return (
    <Cell noline={noline}>
      <Cell.Image src={beat.images && beat.images['400']} alt='' large />
      <Cell.Body>
        <Cell.Line>
          <Cell.LineTitle>
            <AdminEditItemLink item_name={beat.beat_name} item_type={'BEAT'} item_id={beat.id} />
          </Cell.LineTitle>
          {beat.is_blocked && <Cell.LineImportant>{niceBlockedMessage(beat.is_blocked)}</Cell.LineImportant>}
          {!beat.is_blocked && beat.is_featured && <Cell.LineFeatured />}
        </Cell.Line>

        <Cell.Line>
          <UserLink user_id={beat.producer_user_id} username={beat.producer_name} />
          {beat.producer_country && <span>({beat.producer_country}{beat.source === 'amn' ? ' - Local' : ''})</span>}
        </Cell.Line>

        <Cell.Line>
          <div>{formattedDuration(beat.duration_seconds)}</div>
          <div>{dateString(beat.submission_date)}</div>
        </Cell.Line>

        {<Cell.Line>
          {<Cell.PlayCount>{formatStat(beat.play_count)}</Cell.PlayCount>}
          {<Cell.LikeCount>{formatStat(beat.like_count)}</Cell.LikeCount>}
          {<Cell.CommentCount>{formatStat(beat.comment_count)}</Cell.CommentCount>}
          {<Cell.UseCount>{formatStat(beat.use_count)}</Cell.UseCount>}
        </Cell.Line>}

        <Cell.Line>
          <Cell.MusicNote>{beat.genre}</Cell.MusicNote>
          {beat.bpm != null && <div>{parseInt(beat.bpm, 10)} bpm</div>}
          {beat.key != null && <div>{keyScales.find(x => x.id === beat.key).value}</div>}
        </Cell.Line>

        {beat.buy_license_url &&
          <Cell.Line>
            <a href={beat.buy_license_url} target='_blank' rel='noreferrer noopener'>
              {beat.buy_license_url}
            </a>
          </Cell.Line>
        }

        <Cell.Line>
          <AdminBeatListen beat={beat} />
        </Cell.Line>
      </Cell.Body>

      {children}
    </Cell>)
}
AdminBeatCell.ModerationActions = ({pendingCount, reviewedCount, onDetails, onAllow, onBlock}) => (
  <Cell.Actions>
    <Cell.Button onClick={onDetails}>
      {pendingCount} / {pendingCount+reviewedCount}
    </Cell.Button>
    <Cell.Button onClick={onAllow}>
      Allow
    </Cell.Button>
    <Cell.Button onClick={onBlock}>
      Block
    </Cell.Button>
  </Cell.Actions>
)

AdminBeatCell.FeatureActions = ({beat, onFeatureClick, onIgnoreClick}) => {
  var actions = null
  if (beat.is_featured) {
    actions = onFeatureClick && <Cell.Button onClick={() => onFeatureClick(beat)}>Unfeature</Cell.Button>
  } else if (!beat.is_featurable) {
    actions = onIgnoreClick && <Cell.Button onClick={() => onIgnoreClick(beat)}>Unignore</Cell.Button>
  } else {
    actions = <>
      {onFeatureClick && <Cell.Button onClick={() => onFeatureClick(beat)}>Feature</Cell.Button>}
      {onIgnoreClick && <Cell.Button onClick={() => onIgnoreClick(beat)}>Ignore</Cell.Button>}
    </>
  }
  return <Cell.Actions>
    {actions}
  </Cell.Actions>
}

AdminBeatCell.EditActions = ({beat, onFeature, onBlockImage, onConvertToPost, onRunAcrCloud}) => <Cell.Actions>
  {onFeature && <Cell.Button onClick={onFeature}>{beat.is_featured ? 'Unfeature' : 'Feature'}</Cell.Button>}
  {onBlockImage && <Cell.Button onClick={onBlockImage}>{beat.is_image_blocked ? 'Unblock Image' : 'Block Image'}</Cell.Button>}
  {onConvertToPost && <Cell.Button onClick={onConvertToPost}>Convert to Post</Cell.Button>}
  {onRunAcrCloud && <Cell.Button onClick={onRunAcrCloud}>Run ACR Cloud</Cell.Button>}
</Cell.Actions>

export default AdminBeatCell
