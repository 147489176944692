import { Cell } from '../../components'
import UserLink from './UserLink'
import {
  withRouter,
} from '../../shared'

const ItemReportCell = ({report}) => (
  <Cell>
    <Cell.Body>
      <Cell.Line>
        Reported By: <UserLink user_id={report.user_id} />
      </Cell.Line>

      <Cell.Line>
        At:
        <div>{report.created_date}</div>
      </Cell.Line>

      <Cell.Line>
        Reviewed:
        <div>{report.reviewed ? 'Yes' : 'No'}</div>
      </Cell.Line>

      <Cell.Line>
        Details:
        <div>{JSON.stringify(report.metadata)}</div>
      </Cell.Line>
    </Cell.Body>
  </Cell>
)

export default withRouter(ItemReportCell)
