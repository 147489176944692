import React, { useContext } from 'react'
import { AlertContext } from '../../widgets'
import { AudioPlayerContext, Cell } from '../../components'

const AdminBeatListen = ({beat}) => {
  const { showToast } = useContext(AlertContext)
  const { loadAndPlayBeat, pauseItem, isItemPlaying } = useContext(AudioPlayerContext)

  const play = () => {
    loadAndPlayBeat(beat)
  }

  const pause = () => {
    pauseItem(beat.id)
  }

  const onShare = (beat) => {
    navigator.clipboard.writeText(beat.share_url).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the track to your clipboard!')
    })
  }

  return <>
    <Cell.Button onClick={isItemPlaying(beat.id) ? pause : play}>
      {isItemPlaying(beat.id) ? 'Pause' : 'Play'}
    </Cell.Button>

    <a href={beat.audio_file_url}>
      <Cell.Button>
        Download
      </Cell.Button>
    </a>

    <Cell.Button onClick={() => onShare(beat)}>
      Share
    </Cell.Button>
  </>
}

export default AdminBeatListen
