import { Cell } from '../../components'
import AdminConversationLink from './AdminConversationLink'
import AdminMessageCell from './AdminMessageCell'
import UserLink from './UserLink'

const AdminConversationCell = ({conversation, actions, children}) => {
  const newestMessage = conversation.newest_message

  return <Cell>
    <div className='flex-column align-start font-14 gap-4'>
      <div className='flex-row'>
        <AdminConversationLink id={conversation.id} />
        {conversation.is_blocked ? '🚫' : '✅'}
      </div>
      {conversation.members.map((user, index) => <UserLink
        key={index}
        user_id={user.user_id}
        username={user.username}
      />)}
    </div>

    {newestMessage && <AdminMessageCell members={conversation.members} message={newestMessage} />}
    {actions && <Cell.OverflowButton actions={actions} />}
    {children}
  </Cell>
}

export default AdminConversationCell
