import { Link, useMatch } from 'react-router-dom'
import { Button } from '../widgets'

const NavigationButton = ({route, image, text}) => {
  const selected = useMatch(route) ? 'selected' : ''
  return <Link to={route} className={`app-navigation__menu-anchor ${selected}`}>
    <Button className={`app-navigation__menu-button ${selected}`}>
      <img src={image} alt='' />
      {text}
    </Button>
  </Link>
}

export default NavigationButton
