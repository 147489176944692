import React, { Component } from 'react'

import profilePicPlaceholder from '../../images/profile_pic_placeholder.svg'
import { niceIso8601String } from '../../shared'

export default class ProducerProfileContent extends Component {
  render() {
    if (!this.props.profile) {
      return null
    }
    const profilePicsExist = this.props.profile.profile_pics && Object.getOwnPropertyNames(this.props.profile.profile_pics).length > 1
    const profilePic = <img src={profilePicsExist ? this.props.profile.profile_pics['400'] : profilePicPlaceholder} alt='' className='producer-profile-pic' />

    return (
      <div className='layout-content'>
        <div className='producer-profile__container'>
          <div className='producer-profile__left'>
            {profilePic}
          </div>
          <div className='producer-profile__right'>
            <div className='producer-profile__view-content'>
              <div>
                <div className='producer-profile__view-title'>
                  {this.props.username}
                </div>
                <div className='producer-profile__view-subtitle'>
                  {this.props.profile.email}
                  {this.props.audience === 'admin' && <div>{this.props.uuid}</div>}
                  {this.props.audience === 'admin' && <div>{this.props.firebase_uid}</div>}
                  {this.props.audience === 'admin' && <div>Account Created: {niceIso8601String(this.props.created_at)}</div>}
                  {this.props.audience === 'admin' && this.props.details?.premium && <div>⭐ Premium ⭐</div>}
                  {this.props.is_banned &&
                    <div>
                      Banned {this.props.banned_until == null ? 'indefinitely' : `until ${niceIso8601String(this.props.banned_until)}`} {this.props.audience === 'admin' && `by ${this.props.banned_by.name} on ${niceIso8601String(this.props.banned_at)}`}
                    </div>
                  }
                </div>
                <div className='producer-profile__view-bio'>
                  <pre><p>{this.props.profile.bio}</p></pre>
                </div>
              </div>
              <div className='producer-profile__social'>
                {this.props.profile.social_links && this.props.profile.social_links.map((socialLink, index) => {
                  return socialLink.url && <a key={index} href={socialLink.url} target='_blank' rel='noreferrer noopener'>
                    {socialLink.service_name}
                  </a>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
