import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../routes'

const PublicItemLink = ({name, type, id}) => {
  const itemName = name ? name : `${type} | ${id}`
  switch (type) {
    case 'BEAT':
      return <Link className={'public-item-link'} to={routes.dynamic(routes.applinksBeatLink, id)}>{itemName}</Link>
    case 'POST':
      return <Link className={'public-item-link'} to={routes.dynamic(routes.applinksPostLink, id)}>{itemName}</Link>
    case 'PLAYLIST':
      return <Link className={'public-item-link'} to={routes.dynamic(routes.applinksPlaylistLink, id)}>{itemName}</Link>
    default:
      return null
  }
}

export default PublicItemLink
