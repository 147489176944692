import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import * as routes from '../routes'
import { formatStat, Loading, withRouter } from '../shared'
import PaginatedList from './PaginatedList'
import AppStoreBanner from './AppStoreBanner'
import PostCell from './PostCell'
import PublicUserLink from './PublicUserLink'
import { AlertContext } from '../widgets'
import { AudioPlayerContext } from './AudioPlayerContext'
import noteImg from '../images/note.svg'
import playImg from '../images/play.svg'
import likeImg from '../images/like.svg'
import playerPauseImg from '../images/player-pause.svg'
import playerPlayImg from '../images/player-play.svg'

const PublicPlaylistView = (props) => {
  const [loading, setLoading] = useState(true)
  const [playlist, setPlaylist] = useState(null)

  const { handleError } = useContext(AlertContext)
  const {
    setItemsFromPosts,
    playItem,
    pauseItem,
    isItemPlaying,
    onPlayPause,
    isItemInListLoaded,
    isItemInListPlaying,
    ensureItemInListIsPlaying,
  } = useContext(AudioPlayerContext)

  useEffect(() => {
    load()
  }, [])

  const itemsUpdated = (posts) => setItemsFromPosts(posts)

  const playlistId = () => queryString.parse(props.router.location.search).id

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.playlistUrl, playlistId())
    ).then((response) => {
      setPlaylist(response.data)
    }).catch(
      handleError
    ).finally(() => {
      setLoading(false)
    })
  }

  const onTogglePlayPause = (post) => {
    if (!post) { return }
    if (isItemPlaying(post.id)) {
      pauseItem(post.id)
    } else {
      playItem(post.id)
    }
  }

  return loading ? <Loading /> : <>
    <AppStoreBanner />

    <div className='public-view-background'>
      <img src={playlist.images['400']} alt='' />
    </div>

    <div className='public-view-body'>
      <div className='position-relative'>
        <div className='public-view-plays-overlay'>
          <img src={playImg} alt='play' draggable='false'/>
          <span>{formatStat(playlist.play_count)}</span>
        </div>
        <span className='public-view-play-pause-overlay' onClick={() => isItemInListLoaded() ? onPlayPause() : ensureItemInListIsPlaying()}>
          <img src={isItemInListPlaying() ? playerPauseImg : playerPlayImg} alt='play' draggable='false'/>
        </span>
        <img className='public-view-artwork' src={playlist.images['400']} />
      </div>
      <div className='public-view-item-title'>{playlist.name}</div>

      <PublicUserLink user={playlist.user} />

      <div className='public-view-stats'>
        <AudioPlayerContext.Stat image={noteImg} value={formatStat(playlist.item_count)} />
        <AudioPlayerContext.Stat image={likeImg} value={formatStat(playlist.like_count)} />
      </div>

      <div className='width-85vw max-width-600'>
        <PaginatedList
          key={'playlist'}
          loadUrl={routes.dynamic(routes.playlistItemUrl, playlistId())}
          cell={PostCell}
          cellItemPropName={'post'}
          cellAdditionalProps={{
            audience: 'public',
            onPlayPause: (post) => onTogglePlayPause(post),
            isPlaying: (post) => isItemPlaying(post.id),
          }}
          itemsUpdated={itemsUpdated}
        />
      </div>
    </div>
  </>
}

export default withRouter(PublicPlaylistView)
