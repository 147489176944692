import React from 'react'
import { getOS } from '../shared'

import appStoreImg from '../images/app_store.svg'
import googlePlayImg from '../images/google_play.png'
import volocoLogo from '../images/voloco_logo.svg'

const AppStoreBanner = () => {
  const os = getOS()
  return (
    <div className='app-store-banner'>
      <div className='container'>
          <img className='voloco-logo no-padding' src={volocoLogo} alt='Voloco Logo'/>
          <div className='app-store-banner__ctas'>
            {os !== 'android' &&
              <a href='https://apps.apple.com/us/app/id1052970183' target='_blank' rel='noreferrer noopener'>
                <span className='sr-text'>Open in Apple App Store</span>
                <img src={appStoreImg} alt='' />
              </a>
            }
            {os !== 'ios' &&
              <a href='https://play.google.com/store/apps/details?id=com.jazarimusic.voloco' target='_blank' rel='noreferrer noopener'>
                <span className='sr-text'>Open in Google Play Store</span>
                <img src={googlePlayImg} alt='' />
              </a>
            }
          </div>
      </div>
    </div>
  )
}

export default AppStoreBanner
