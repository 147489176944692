import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import * as routes from '../routes'
import { formatStat, Loading, withRouter } from '../shared'
import { AlertContext } from '../widgets'
import AppStoreBanner from './AppStoreBanner'
import PublicUserLink from './PublicUserLink'
import { AudioPlayerContext } from './AudioPlayerContext'
import likeImg from '../images/like.svg'
import playImg from '../images/play.svg'
import commentImg from '../images/comment.svg'
import playerPauseImg from '../images/player-pause.svg'
import playerPlayImg from '../images/player-play.svg'

const PublicPostView = (props) => {
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState(null)

  const { handleError } = useContext(AlertContext)
  const { setItemsFromPosts, isItemPlaying, pauseItem, loadAndPlayPost } = useContext(AudioPlayerContext)

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (!post) { return }
    setItemsFromPosts([post])
  }, [post])

  const getId = () => queryString.parse(props.router.location.search).id

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.getPostUrl, getId())
    ).then((response) => {
      setPost(response.data)
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return loading ? <Loading /> : <>
    <AppStoreBanner />

    <div className='public-view-background'>
      <img src={post.signed_image_urls['400']} alt='' />
    </div>

    <div className='public-view-body'>
      <div className='position-relative'>
        <div className='public-view-plays-overlay'>
          <img src={playImg} alt='play' draggable='false'/>
          <span>{formatStat(post.play_count)}</span>
        </div>
        <span className='public-view-play-pause-overlay' onClick={() => isItemPlaying(post.id) ? pauseItem(post.id) : loadAndPlayPost(post)}>
          <img src={isItemPlaying(post.id) ? playerPauseImg : playerPlayImg} alt='play' draggable='false'/>
        </span>
        <img className='public-view-artwork' src={post.signed_image_urls['400']} />
      </div>
      <div className='public-view-item-title'>{post.title}</div>

      <PublicUserLink user_id={post.user_id} username={post.user_name} user_images={post.user_profile_images} />

      <div className='public-view-stats'>
        <AudioPlayerContext.Stat image={likeImg} value={formatStat(post.like_count)} />
        <AudioPlayerContext.Stat image={commentImg} value={formatStat(post.comment_count)} />
      </div>
    </div>
  </>
}

export default withRouter(PublicPostView)
