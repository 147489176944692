import classNames from './util'

const Layout = (props) => <div
  className={classNames('widgets-Layout', props.className)}
>
  {props.children}
</div>

Layout.Header = (props) => <div
  className={classNames('widgets-Layout-Header', props.className)}
>
  {props.children}
</div>

export default Layout
