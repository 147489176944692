import React, { useContext } from 'react'
import { AlertContext } from '../../widgets'
import { AudioPlayerContext, Cell } from '../../components'

const AdminPostListen = ({post}) => {
  const { showToast } = useContext(AlertContext)
  const { loadAndPlayPost, pauseItem, isItemPlaying } = useContext(AudioPlayerContext)

  const play = () => {
    loadAndPlayPost(post)
  }

  const pause = () => {
    pauseItem(post.id)
  }

  const onShare = (post) => {
    navigator.clipboard.writeText(post.share_url).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the track to your clipboard!')
    })
  }

  return <>
    <Cell.Button onClick={isItemPlaying(post.id) ? pause : play}>
      {isItemPlaying(post.id) ? 'Pause' : 'Play'}
    </Cell.Button>

    <a href={post.signed_track_url}>
      <Cell.Button>
        Download
      </Cell.Button>
    </a>

    <Cell.Button onClick={() => onShare(post)}>
      Share
    </Cell.Button>
  </>
}

export default AdminPostListen
