import { Link } from 'react-router-dom'
import * as routes from '../../routes'
import { Navigation, NavigationButton } from '../../components'
import { LogoutButton } from '../../auth'
import { Button } from '../../widgets'

import cloudImg from '../../images/cloud.svg'
import profileImg from '../../images/profile.svg'
import tracksImg from '../../images/tracks.svg'
import beatsImg from '../../images/beats.svg'
import boostImg from '../../images/boost.svg'
import noteImg from '../../images/note.svg'

const ProducerNavigation = ({router}) => <Navigation>
  <Link to={routes.producerSubmitPath}>
    <Button className='app-navigation__upload-button'>
      <img src={cloudImg} alt='' />
      Upload Beat
    </Button>
  </Link>

  <NavigationButton route={routes.producerProfilePath} image={profileImg} text='Profile' />
  <NavigationButton route={routes.producerBeatsPath} image={beatsImg} text='Beats' />
  <NavigationButton route={routes.producerTopTracksPath} image={noteImg} text='Tracks' />
  <NavigationButton route={routes.producerPlaylistsPath} image={tracksImg} text='Playlists' />
  <NavigationButton route={routes.producerBoostsPath} image={boostImg} text='Boosts' />

  <div className='app-navigation__divider' disabled />

  <LogoutButton />
</Navigation>


export default ProducerNavigation
