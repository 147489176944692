import classNames from './util'

const Menu = (props) => <div
  className={classNames('widgets-Menu', props.className)}
>
  {props.children}
</div>

Menu.Item = (props) => <div
  className={classNames('widgets-Menu-Item', props.className)}
>
  {props.children}
</div>

export default Menu
