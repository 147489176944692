import { createContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import classNames from './util'
import Button from './Button'

const localizations = {
  'username_min_length': 'Username must be at least 2 characters.',
  'username_max_length': 'Username cannot be more than 24 characters.',
  'username_characters': 'Username can only contain letters, numbers, underscores, and periods.',
  'username_end_with_period': 'Username cannot end with a period.',
  'username_unavailable': 'This username is already taken. Please enter a unique username.',
  'bio_max_length': 'Bio cannot be more than 250 characters.',
  'bio_max_newlines': 'Bio cannot have more than 4 new lines.',
  'beat_name_min_length': 'Beat name must be at least 2 characters.',
  'beat_name_max_length': 'Beat name cannot be more than 50 characters.',
  'audio_file_load_error': 'An error occurred for the chosen audio file. Try using a different browser, or encoding the audio a different way. If this continues being an issue, please email support@resonantcavity.com with the following information:'
}

const getErrorMessage = (error) => {
  if (!error.response || !error.response.data || !error.response.data.errors) {
    // unexpected errors
    console.log(error)
    Sentry.captureException(error)
    return 'Something went wrong. Please try again or email support@resonantcavity.com.'
  }

  // expected, localizable, user facing errors like username validation
  const errors = error.response.data.errors
  console.log(JSON.stringify(errors, null, '  '))
  const messages = errors.map((error) => {
    return localizations[error.localization_code] || error.message
  }).join('\n\n')
  return messages
}

export const AlertContext = createContext('alert-context')

export const AlertContextProvider = ({children}) => {
  // Alerts
  const [alertQueue, setAlertQueue] = useState([])

  const showAlert = ({ title, message, wide, callback }) => {
    setAlertQueue(alertQueue => [...alertQueue, { title, message, wide, okButton: 'OK', callback }])
  }

  const showAlertError = (message) => {
    showAlert({ title: 'Error', message })
  }

  const handleError = (error) => {
    showAlertError(getErrorMessage(error))
  }

  const showAlertConfirm = ({ title, message, callback }) => {
    setAlertQueue(alertQueue => [...alertQueue, { title, message, cancelButton: 'Cancel', okButton: 'OK', callback }])
  }

  const nextAlert = () => {
    const nextAlertQueue = alertQueue.slice(1)
    setAlertQueue([])
    setTimeout(() => {
      setAlertQueue(alertQueue => [...nextAlertQueue, ...alertQueue])
    }, 200)
  }

  // Toasts
  const [currentToast, setCurrentToast] = useState()

  const showToast = (message) => {
    const show = () => {
      const timeout = setTimeout(() => {
        setCurrentToast({ message, visible: false })
      }, 3000)
      setCurrentToast({ message, visible: true, timeout })
    }

    if (currentToast && currentToast.timeout) {
      clearTimeout(currentToast.timeout)
      setCurrentToast({ ...currentToast, visible: false, timeout: undefined })
      setTimeout(show, 200)
    } else {
      show()
    }
  }

  const currentAlert = alertQueue[0]
  return (
    <AlertContext.Provider
      value={{
        showAlert,
        showAlertError,
        showAlertConfirm,
        showToast,
        handleError,
      }}
    >
      {children}

      {/* alerts */}
      {<div className={classNames('alert', !!currentAlert && 'show')}>
        {!!currentAlert && <div className={classNames('alert-body', !!currentAlert.wide && 'alert-body-wide')}>
          {!!currentAlert.title && <div className='alert-body-title'>
            {currentAlert.title}
          </div>}
          {!!currentAlert.message && <div className='alert-body-message'>
            {currentAlert.message}
          </div>}
          {!!currentAlert.okButton && <Button className='alert-body-button' className='red flexrow' onClick={() => {
            if (currentAlert && typeof currentAlert.callback === 'function') {
              currentAlert.callback()
            }
            nextAlert()
          }}>
            {currentAlert.okButton}
          </Button>}
          {!!currentAlert.cancelButton && <Button className='alert-body-button' className='red-outlined flexrow' onClick={nextAlert}>
            {currentAlert.cancelButton}
          </Button>}
        </div>}
      </div>}

      {/* toasts */}
      {<div className={classNames('toast', !!currentToast && currentToast.visible && 'show')}>
        {!!currentToast && <div className='toast-message'>
          {currentToast.message}
        </div>}
      </div>}
    </AlertContext.Provider>
  )
}
