import React, { Component } from 'react'
import { Layout } from '../../widgets'
import * as routes from '../../routes'
import { AdminPostCell, FilterPicker, } from '../components'
import { NavigationTab, PaginatedList } from '../../components'

class AdminPosts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 'Trending',
      region: null,
    }
  }

  render = () => {
    const tabs = {
      'Trending': <PaginatedList
        featuredUrl={routes.adminPostFeaturedUrl}
        notFeaturableUrl={routes.adminPostNotFeaturableUrl}
        loadUrl={routes.adminPostTrendingUrl}
        loadParams={{}}
        cell={AdminPostCell}
        cellItemPropName={'post'}
        datePicker={true}
        featureActionUpdateBehavior={'filter'}
      />,
      'Featured': <PaginatedList
        key={'recently_featured'}
        loadUrl={routes.adminPostFeaturedUrl}
        loadParams={{}}
        featuredUrl={routes.adminPostFeaturedUrl}
        cell={AdminPostCell}
        cellItemPropName={'post'}
        featureActionUpdateBehavior={'filter'}
      />,
      'Ignored': <PaginatedList
        key={'recently_ignored'}
        loadUrl={routes.adminPostNotFeaturableUrl}
        loadParams={{}}
        notFeaturableUrl={routes.adminPostNotFeaturableUrl}
        cell={AdminPostCell}
        cellItemPropName={'post'}
        featureActionUpdateBehavior={'filter'}
      />,

      'Hot Feed': <>
        <FilterPicker.Region onChange={(value) => this.setState({ region: value[0] })} />
        <PaginatedList
          key={this.state.region ? `hot_local_${this.state.region}` : 'all_scored'}
          loadUrl={routes.adminPostFeedUrl}
          loadParams={this.state.region ? {'type': 'hot_local', 'region': this.state.region} : {'type': 'all_scored'}}
          featuredUrl={routes.adminPostFeaturedUrl}
          notFeaturableUrl={routes.adminPostNotFeaturableUrl}
          cell={AdminPostCell}
          cellItemPropName={'post'}
        />
      </>,
      'Featured Feed': <PaginatedList
        key={'featured_new'}
        loadUrl={routes.adminPostFeedUrl}
        loadParams={{'type': 'featured_new'}}
        featuredUrl={routes.adminPostFeaturedUrl}
        notFeaturableUrl={routes.adminPostNotFeaturableUrl}
        cell={AdminPostCell}
        cellItemPropName={'post'}
      />,
      'New Feed': <>
        <FilterPicker.Region onChange={(value) => this.setState({ region: value[0] })} />
        <PaginatedList
          key={this.state.region ? `new_local_${this.state.region}` : 'all_new'}
          loadUrl={routes.adminPostFeedUrl}
          loadParams={this.state.region ? {'type': 'new_local', 'region': this.state.region} : {'type': 'all_new'}}
          featuredUrl={routes.adminPostFeaturedUrl}
          notFeaturableUrl={routes.adminPostNotFeaturableUrl}
          cell={AdminPostCell}
          cellItemPropName={'post'}
        />
      </>,
    }

    return (
      <div className='body-container'>
        <Layout>
          <Layout.Header>Tracks</Layout.Header>
          <div className='moderator-content'>

            <div className='navigation-tabs'>
              {Object.keys(tabs).map((tab, i) => (
                <NavigationTab
                  key={i}
                  text={tab}
                  isActive={this.state.tab === tab}
                  onClick={() => {
                    if (this.state.tab !== tab) {
                      this.setState({ tab: tab })
                    }
                  }}
                />
              ))}
            </div>

            {tabs[this.state.tab]}

          </div>
        </Layout>
      </div>
    )
  }
}

export default AdminPosts
