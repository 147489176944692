import { Link } from 'react-router-dom'
import * as routes from '../routes'

const PublicUserLink = (props) => {
  // allow callers to send props.user or props.user_id, props.username, props.user_images
  const link = props.user?.share_url || routes.dynamic(routes.applinksPublicUserProfileLink, props.user_id)
  const image = props.user?.profile?.profile_pics['100'] || props.user_images?.['100']
  const username = props.user?.username || props.username

  return <Link className={`public-user-link ${props.small ? 'public-user-link-small' : ''}`} to={link}>
    {image && <img src={image} />}
    {username}
  </Link>
}

export default PublicUserLink
