import React from 'react'
import * as moment from 'moment'
import { humanizedDuration, formattedDate } from '../../shared'
import { BeatCell, PostCell , Cell } from '../../components'

const ProducerActiveBoostCell = ({boost, children}) => {
  const remaining = moment(boost.end_at).diff(moment(boost.start_at))
  return <>
    <Cell noline>
      <Cell.Body>
        <Cell.Line>
          <div>Started on {formattedDate(boost.created_at)} with {humanizedDuration(moment(), moment(boost.end_at))} remaining</div>
        </Cell.Line>
      </Cell.Body>
    </Cell>
    {boost.item_type === 'BEAT' ? <BeatCell beat={boost.item} smallImage/> : <PostCell post={boost.item} smallImage/>}
    {children}
  </>
}

export default ProducerActiveBoostCell
