import React from 'react'
import AdminEditItemLink from './AdminEditItemLink'
import UserLink from './UserLink'
import { Cell } from '../../components'

const AdminCommentCell = ({comment, actions, children}) => (
  <Cell>
    {comment.user_profile_images && <Cell.Image src={comment.user_profile_images['200']} alt='' />}
    <Cell.Body>
      <Cell.Line>
        <Cell.LineTitle>
          <UserLink user_id={comment.user_id} username={comment.username ?? 'Unknown'} />
        </Cell.LineTitle>
      </Cell.Line>

      <Cell.Line>
        <AdminEditItemLink item_type={comment.item_type} item_id={comment.item_id} />
      </Cell.Line>

      <Cell.Line>
        <div>{comment.data}</div>
      </Cell.Line>

      <Cell.Line>
        <div>{`${comment.likes} likes`}</div>
      </Cell.Line>
    </Cell.Body>

    {actions && <Cell.OverflowButton actions={actions} />}

    {children}
  </Cell>
)

AdminCommentCell.ModerationActions = ({pendingCount, reviewedCount, onDetails, onAllow, onBlock}) => (
  <Cell.Actions>
    {onDetails && <Cell.Button onClick={onDetails}>
      {pendingCount} / {pendingCount+reviewedCount}
    </Cell.Button>}
    {onAllow && <Cell.Button onClick={onAllow}>
      Allow
    </Cell.Button>}
    {onBlock && <Cell.Button onClick={onBlock}>
      Block
    </Cell.Button>}
  </Cell.Actions>
)

export default AdminCommentCell
