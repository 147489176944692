import { Link } from 'react-router-dom'
import * as routes from '../../routes'

const AdminConversationLink = ({id, className}) => <Link
  className={className}
  to={routes.dynamic(routes.adminConversationPath, id)}
>
  {id}
</Link>

export default AdminConversationLink
