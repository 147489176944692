import React from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import axios from 'axios'
import * as moment from 'moment'
import { Layout, Form, Input, Tooltip } from './widgets'
import * as routes from './routes'
import authClient from './auth/authClient'

export const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    )
  }
  return ComponentWithRouterProp
}

export const allowedImageTypes = 'image/jpeg, image/png'
export const allowedAudioTypes = 'audio/aac, audio/aiff, audio/ogg, audio/mpeg, audio/wav, audio/x-wav, audio/x-m4a'

export const rejectEmptyValues = (object) => {
  if (object === null || Array.isArray(object) || typeof(object) !== 'object') {
    return object
  }
  return Object.entries(object).reduce((out, entry) => {
    const key = entry[0]
    const value = rejectEmptyValues(entry[1])
    const isEmpty = !value || (Array.isArray(value) && !value.length) || (typeof(value) === 'object' && !Object.keys(value).length)
    if (!isEmpty) { out[key] = value }
    return out
  }, {})
}

export const filenameFromSignedUrl = (signedUrl) => {
  const lastPathEnd = signedUrl.lastIndexOf('/') + 1
  const queryParamBegin = signedUrl.indexOf('?')
  if (lastPathEnd >= queryParamBegin) {
    return signedUrl
  }
  return signedUrl.substring(lastPathEnd, queryParamBegin).replace('-transcoded.m4a', '')
}

export const awsClient = axios.create(
  { 'crossDomain': true }
)

export const Loading = () => <div className='loading-container'>
  <div className='loading' />
</div>

export const dateString = (date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const formattedDate = new Date(
    date.date.year,
    date.date.month - 1,
    date.date.day,
    date.time.hour,
    date.time.minute,
    date.time.second
  )

  return `${monthNames[formattedDate.getMonth()]} ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`
}

export const niceIso8601String = (iso8601String) => {
  return moment(iso8601String).format('MMMM DD YYYY')
}

export const niceIso8601Time = (iso8601String) => {
  return moment(iso8601String).format('MMMM DD YYYY HH:mm:ss')
}

export const cleanText = (text) => {
  return text.replace(/[^\w.-]+/g, '_')
}

export const formattedDuration = (seconds) => {
  return Math.floor(seconds / 60) + ':' + `${Math.floor(seconds % 60)}`.padStart(2, '0')
}

export const toRezcavErrorResponse = (rezcavErrors) => {
  return {
    response: {
      data: {
        errors: rezcavErrors
      }
    }
  }
}

export const toRezcavError = (localizationCode, message) => {
  return {
    localization_code: localizationCode,
    message: message
  }
}

const validateFileIsReadable = (file, callback) => {
  // try to read the first byte of the file
  // This prevents cloud files like files coming from Google Drive from passively
  // fail to upload when submitting the AWS put call through Axios
  const blob = file.slice(0, 1)
  const reader = new FileReader()
  reader.onload = () => {
    callback()
  }
  reader.onerror = () => {
    callback('Could not read the chosen file. This is likely due to choosing a file from a cloud service like Google Drive. To solve this, please download the file to your device first before selecting.')
  }
  reader.readAsArrayBuffer(blob)
}

export const validateImageFile = (file, callback) => {
  if (!allowedImageTypes.split(',').map(x => x.trim()).includes(file.type)) {
    callback(`The chosen file is not a valid image type: ${file.type}`)
    return
  }
  if (file.size > 15000000) {
    callback(`The chosen image is ${formatBytes(file.size)}. Please try again with an image that is less than 15 MB.`)
    return
  }
  validateFileIsReadable(file, callback)
}

export const validateAudioFile = (file, callback) => {
  if (!allowedAudioTypes.split(',').map(x => x.trim()).includes(file.type)) {
    callback(`The chosen file is not a valid audio type: ${file.type}`)
    return
  }
  if (file.size > 75000000) {
    callback(`The chosen file is ${formatBytes(file.size)}. Please try again with a file that is less than 75 MB.`)
    return
  }
  validateFileIsReadable(file, callback)
}

function formatBytes(bytes) {
  if (bytes === 0) {
    return '0 Bytes'
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i]
}

export const maxNumBioCharacters = 250
export const maxNumBioNewlines = 4

export const isBioValid = (bioText) => {
  const numNewlines = (bioText.match(/\n/g) || []).length
  const numCharacters = bioText.length
  return numCharacters <= maxNumBioCharacters && numNewlines <= maxNumBioNewlines
}

export const isProfileComplete = () => {
  const user = authClient.currentUser()
  const profile = user.profile
  return user &&
    user.username &&
    Object.keys(profile.profile_pics).length > 0
}

export const withProfileCompleteness = (component) => {
  if (isProfileComplete()) { return component }
  return (
    <Layout>
      <Layout.Header>
        You need to
        <Link to={routes.producerProfileEditPath}> update your profile </Link>
        first.
      </Layout.Header>
    </Layout>
  )
}

export const niceBlockedMessage = (isBlocked) => {
  return isBlocked ? (
    <Tooltip title={'This content has been removed for violating terms of service.'}>
      {termsOfService('Removed')}
    </Tooltip>
  ) : null
}

export const termsOfService = (label) => (
  <a
    className='terms-of-service'
    href='https://resonantcavity.com/appterms'
    target='_blank'
    rel='noopener noreferrer'
  >
    {label ? label : 'Terms of Service'}
  </a>
)

export const termsOfServiceLabel = (
  <div className='ant-form-item-label'>
    <label className='terms-of-service-container'>
      By agreeing to our {termsOfService()} you
      <ul>
        <li>
          Claim that you own the copyright to the Content you upload
        </li>
        <li>
          Retain ownership of your Content
        </li>
        <li>
          Grant Resonant Cavity a royalty-free, world-wide,
          non-exclusive, transferable license to use, reproduce, modify,
          edit, publish, distribute and display such Content
        </li>
        <li>
          If you choose, Resonant Cavity will cease to make your Content
          available upon request.
        </li>
      </ul>
      <p>Enter your name below to agree to our {termsOfService()} *</p>
    </label>
  </div>
)

export const keyScales = [
  { id: 0, value: 'C Major' },
  { id: 1, value: 'C♯ / D♭ Major' },
  { id: 2, value: 'D Major' },
  { id: 3, value: 'D♯ / E♭ Major' },
  { id: 4, value: 'E Major' },
  { id: 5, value: 'F Major' },
  { id: 6, value: 'F♯ / G♭ Major' },
  { id: 7, value: 'G Major' },
  { id: 8, value: 'G♯ / A♭ Major' },
  { id: 9, value: 'A Major' },
  { id: 10, value: 'A♯ / B♭ Major' },
  { id: 11, value: 'B Major' },
  { id: 12, value: 'C Minor' },
  { id: 13, value: 'C♯ / D♭ Minor' },
  { id: 14, value: 'D Minor' },
  { id: 15, value: 'D♯ / E♭ Minor' },
  { id: 16, value: 'E Minor' },
  { id: 17, value: 'F Minor' },
  { id: 18, value: 'F♯ / G♭ Minor' },
  { id: 19, value: 'G Minor' },
  { id: 20, value: 'G♯ / A♭ Minor' },
  { id: 21, value: 'A Minor' },
  { id: 22, value: 'A♯ / B♭ Minor' },
  { id: 23, value: 'B Minor' },
]

export const bpms = [
  { id: { gte: '1', lte: '60' }, value: '1-60' },
  { id: { gte: '60', lte: '80' }, value: '60-80' },
  { id: { gte: '80', lte: '100' }, value: '80-100' },
  { id: { gte: '100', lte: '120' }, value: '100-120' },
  { id: { gte: '120', lte: '140' }, value: '120-140' },
  { id: { gte: '140', lte: '160' }, value: '140-160' },
  { id: { gte: '160', lte: '180' }, value: '160-180' },
  { id: { gte: '180', lte: '200' }, value: '180-200' },
  { id: { gte: '200', lte: '300' }, value: '200+' },
]

export const creatorTypes = [
  { id: 'artist', value: 'Artist' },
  { id: 'producer', value: 'Producer' },
]

export const itemBlockTypes = [
  { id: 'COPYRIGHT', value: 'Copyright Infringement' },
  { id: 'REPORTS', value: 'Reports' },
  { id: 'USER_BAN', value: 'User Ban' },
]

export const itemTypes = [
  { id: 'BEAT', value: 'Beat' },
  { id: 'POST', value: 'Post' },
  { id: 'PLAYLIST', value: 'Playlist' },
]

export const reportableTypes = [
  { id: 'USER', value: 'User' },
  { id: 'POST', value: 'Post' },
  { id: 'BEAT', value: 'Beat' },
  { id: 'PLAYLIST', value: 'Playlist' },
  { id: 'COMMENT', value: 'Comment' },
]

export const beatSorts = [
  'Relevance',
  'Oldest First',
  'Newest First',
]

export const regions = [
  { id: null, value: 'Global' },
  { id: 'north_america', value: 'North America' },
  { id: 'south_america', value: 'South America' },
  { id: 'europe', value: 'Europe' },
  { id: 'middle_east_and_north_africa', value: 'Middle East and North Africa' },
  { id: 'subsaharan_africa', value: 'Subsaharan Africa' },
  { id: 'south_asia', value: 'South Asia' },
  { id: 'east_and_southeast_asia', value: 'East and Southeast Asia' },
]

export const userBanReporterTypes = [
  { id: 'ADMIN', value: 'Admin' },
  { id: 'SYSTEM', value: 'System' },
]

export const beatSortTextToSearchSort = (sort) => {
  if (sort === 'Relevance') {
    return null
  } else if (sort === 'Oldest First') {
    return [{ field: 'submission_date', order: 'asc' }]
  } else if (sort === 'Newest First') {
    return [{ field: 'submission_date', order: 'desc' }]
  }
}

export const postSorts = [
  'Relevance',
  'Newest Featured',
  'Oldest Featured',
  'Newest Created',
  'Oldest Created',
]

export const postSortTextToSearchSort = (sort) => {
  if (sort === 'Relevance') {
    return null
  } else if (sort === 'Oldest Featured') {
    return [{ field: 'featured_date', order: 'asc' }]
  } else if (sort === 'Newest Featured') {
    return [{ field: 'featured_date', order: 'desc' }]
  } else if (sort === 'Oldest Created') {
    return [{ field: 'created_date', order: 'asc' }]
  } else if (sort === 'Newest Created') {
    return [{ field: 'created_date', order: 'desc' }]
  }
}

export const requiredFieldsPreface = (<p className='submit-preface'>All fields marked with * are required.</p>)

export const buyLicenseUrlFormInput = (onChange, value) => (
  <Form.Item label='Buy License URL'>
    <label className='terms-of-service-container'>
      <div>Do you have this beat listed on a beat distribution platform? Enter the URL to buy the license here.</div>
      <div>Including this link will give Voloco users direct access to purchase the distribution rights.</div>
    </label>
    <Input value={value} placeholder='https://www.beatplatform.com/beat/your-beat-000' type='text' onChange={onChange} />
  </Form.Item>
)

export const getOS = () => {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

  if (macosPlatforms.indexOf(platform) !== -1) {
    return 'macos'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return 'ios'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return 'windows'
  } else if (/Android/.test(userAgent)) {
    return 'android'
  } else if (/Linux/.test(platform)) {
    return 'linux'
  }
}

export const arrayDiff = (array1, array2) => array1.filter(x => !array2.includes(x))

export const formatStat = (stat) => {
  if (stat >= 1000000) {
    return `${(stat / 1000000).toFixed(1)}M`
  } else if (stat >= 1000) {
    return `${(stat / 1000).toFixed(1)}k`
  } else {
    return stat
  }
}

export const toPascalCase = (string) => string.replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase())

export const formattedDate = (timestamp) => moment(timestamp).utc().format('MMMM Do YYYY');

export const humanizedDuration = (since, until) => {
  const minutes = Math.max(1, until.diff(since, 'minutes')) // never show "0m", always "1m"
  const hours = until.diff(since, 'hours')
  const days = until.diff(since, 'days')
  if (minutes < 60) {
    return `${minutes} ${lazyPluralize(minutes, 'minute')}`
  } else if (hours < 24) {
    return `${hours} ${lazyPluralize(hours, 'hour')}`
  } else if (days < 7) {
    return `${days} ${lazyPluralize(days, 'day')}`
  } else {
    const weeks = Math.floor(days/7)
    return `${weeks} ${lazyPluralize(weeks, 'week')}`
  }
}

export const lazyPluralize = (num, str) => {
  return num === 1 ? str : str + 's'
}
