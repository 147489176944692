import { useCallback, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'
import * as routes from '../../routes'
import { Input, AlertContext } from '../../widgets'
import globals from '../../globals'
import {
  beatSorts,
  beatSortTextToSearchSort,
  postSorts,
  postSortTextToSearchSort,
  Loading,
  rejectEmptyValues,
} from '../../shared'
import NavigationTab from '../../components/NavigationTab'
import FilterPicker from './FilterPicker'
import AdminBeatCell from './AdminBeatCell'
import AdminPostCell from './AdminPostCell'
import AdminUserCell from './AdminUserCell'

const tabText = {
  'users': 'Users',
  'beats': 'Beats',
  'posts': 'Tracks',
}

const searchUrls = {
  'users': routes.adminSearchUsersUrl,
  'beats': routes.adminSearchBeatsUrl,
  'posts': routes.adminSearchPostsUrl,
}

const Search = (props) => {
  const [state, replaceState] = useState({
    loading: false,
    query: null,
    activeTab: null,

    users: [],
    beats: [],
    posts: [],

    selectedSort: null,
    selectedGenres: props.defaultGenres || [],
    selectedBpms: props.defaultBpms || [],
    selectedKeys: props.defaultKeys || [],
    selectedCreatorTypes: props.defaultCreatorTypes || [],
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const { handleError } = useContext(AlertContext)

  useEffect(() => {
    search(state)
  }, [
    state.activeTab,
    state.selectedSort,
    state.selectedSort,
    state.selectedGenres,
    state.selectedBpms,
    state.selectedKeys,
    state.selectedCreatorTypes,
  ])

  useEffect(() => {
    handleTabChange('users')
  }, [])

  const handleTabChange = (tab) => {
    if (state.activeTab === tab) {
      return
    }
    setState({ activeTab: tab })
  }

  const search = (state) => {
    if (!state.activeTab) {
      return
    }
    setState({ loading: true })
    var url = searchUrls[state.activeTab]
    const body = rejectEmptyValues({
      query: state.query,
      filter: {
        genre: state.selectedGenres,
        bpm: state.selectedBpms,
        key: state.selectedKeys,
        creator_type: state.selectedCreatorTypes,
      }
    })
    var sort = null
    if (state.activeTab === 'beats') {
      sort = beatSortTextToSearchSort(state.selectedSort)
    } else if (state.activeTab === 'posts') {
      sort = postSortTextToSearchSort(state.selectedSort)
    }
    if (state.activeTab !== 'users' && sort) {
      body.sort = sort
    }
    axios.post(url, body).then((response) => {
      setState({ [state.activeTab]: response.data.data })
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setState({ loading: false })
    })
  }

  const debouncedSearch = useCallback(debounce(search, 500), [])

  useEffect(() => {
    debouncedSearch(state)
  }, [state.query])

  const onInputChange = (query) => {
    setState({ query })
  }

  const onFilterChanged = (stateKey, checkedValues) => {
    setState({ [stateKey]: checkedValues })
  }

  const onSortChanged = (value) => {
    setState({ selectedSort: value })
  }

  const toggleFeatured = (item, url) => {
    axios.post(url, {
      id: item.id,
      featured: !item.is_featured,
    }).then((response) => {
      search(state)
    }).catch((error) => {
      handleError(error)
    })
  }
  const onFeatureBeat = (beat) => toggleFeatured(beat, routes.adminBeatFeaturedUrl)
  const onFeaturePost = (post) => toggleFeatured(post, routes.adminPostFeaturedUrl)

  const toggleNotFeaturable = (item, url) => {
    axios.post(url, {
      id: item.id,
      not_featurable: !!item.is_featurable,
    }).then((response) => {
      search(state)
    }).catch((error) => {
      handleError(error)
    })
  }
  const onIgnoreBeat = (beat) => toggleNotFeaturable(beat, routes.adminBeatNotFeaturableUrl)
  const onIgnorePost = (post) => toggleNotFeaturable(post, routes.adminPostNotFeaturableUrl)

  const sorts = state.activeTab === 'beats' ? beatSorts : postSorts
  const sortOption = state.activeTab !== 'users' && <div className='search-sort'>
    <h6>Sort</h6>
    <select
      defaultValue={sorts[0]}
      onChange={event => onSortChanged(event.target.value)}
    >
      {sorts.map((sort, index) =>
        <option key={index} className='select' value={sort}>{sort}</option>
      )}
    </select>
  </div>

  var filterPickers = null
  if (state.activeTab === 'users') {
    filterPickers =
      <div className='search-filters'>
        <FilterPicker.CreatorType defaultValues={props.defaultCreatorTypes} onChange={(values) => onFilterChanged('selectedCreatorTypes', values)} />
      </div>
  } else if (state.activeTab === 'beats') {
    filterPickers =
      <div className='search-filters'>
        <FilterPicker.Bpm defaultValues={props.defaultBpms} onChange={(values) => onFilterChanged('selectedBpms', values)} />
        <FilterPicker.Key defaultValues={props.defaultKeys} onChange={(values) => onFilterChanged('selectedKeys', values)} />
        <FilterPicker.Genre defaultValues={props.defaultGenres} choices={globals.genres} onChange={(values) => onFilterChanged('selectedGenres', values)} />
      </div>
  } else if (state.activeTab === 'posts') {
    filterPickers =
      <div className='search-filters'>
        <FilterPicker.Genre defaultValues={props.defaultGenres} choices={globals.genres} onChange={(values) => onFilterChanged('selectedGenres', values)} />
      </div>
  }

  var content = <Loading />
  if (!state.loading) {
    content = (
      <div className='search-no-results'>
        No results found.
      </div>
    )
    if (state.activeTab === 'users' && state.users.length > 0) {
      content = (
        <div className='beats-search-results'>
          {state.users.map((user, i) =>
            <AdminUserCell
              key={i}
              user={user}
              actions={props.userRowActionTitle && [
                { title: props.userRowActionTitle, onClick: () => props.userRowActionOnClick(user) },
              ]}
            />
          )}
        </div>
      )
    } else if (state.activeTab === 'beats' && state.beats.length > 0) {
      content = (
        <div className='beats-search-results'>
          {state.beats.map((beat, i) =>
            <AdminBeatCell key={i} beat={beat}>
              <AdminBeatCell.FeatureActions beat={beat} onFeatureClick={onFeatureBeat} onIgnoreClick={onIgnoreBeat} />
            </AdminBeatCell>
          )}
        </div>
      )
    } else if (state.activeTab === 'posts' && state.posts.length > 0) {
      content = (
        <div className='top-tracks-search-results'>
          {state.posts.map((post, i) =>
            <AdminPostCell key={i} post={post}>
              <AdminPostCell.FeatureActions post={post} onFeatureClick={onFeaturePost} onIgnoreClick={onIgnorePost} />
            </AdminPostCell>
          )}
        </div>
      )
    }
  }

  return (
    <>
      <Input
        className='search-input'
        type='text'
        placeholder='Type here to start searching'
        onChange={(event) => onInputChange(event.target.value)}
      />
      <br />
      <br />
      <div className='moderator-content'>
        <div className='navigation-tabs'>
          {props.tabs.map((tab, index) => (
            <NavigationTab
              key={index}
              isActive={state.activeTab === tab}
              onClick={() => { handleTabChange(tab) }}
              text={tabText[tab]}
            />
          ))}
        </div>
        {sortOption}
        <div className='search-container'>
          {filterPickers}
          {content}
        </div>
      </div>
    </>
  )
}

export default Search
