import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { Form, Input, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import authClient from '../auth/authClient'
import globals from '../globals'
import { AudioFilePicker, ImageFilePicker } from '../components'
import {
  awsClient,
  requiredFieldsPreface,
  termsOfServiceLabel,
  withProfileCompleteness,
  rejectEmptyValues,
  keyScales,
  buyLicenseUrlFormInput,
  withRouter,
} from '../shared'

const initialState = {
  trackName: null,
  genre: null,
  bpm: null,
  key: null,
  electronicSignature: null,
  audioFile: null,
  imageFile: null,
  imageFileContents: null,
  buyLicenseUrl: null,

  submitting: false,
}

const SubmitBeat = (props) => {

  const { showAlertError, handleError } = useContext(AlertContext)

  const [state, replaceState] = useState(initialState)

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const resetForm = () => {
    setState(initialState)
  }

  const getUrlBeat = () => {
    return axios.get(routes.getUrlBeatUrl, {
      params: {
        user_id: authClient.userId(),
      }
    })
  }

  const uploadToAws = (signedTrackUrl, signedImageUrl) => {
    const { audioFile, imageFile } = state
    return new Promise((resolve, reject) => {
      awsClient
        .put(signedTrackUrl, audioFile, { headers: { 'Content-Type': 'binary/octet-stream' } })
        .then(() => {
          awsClient
            .put(signedImageUrl, imageFile, { headers: { 'Content-Type': 'binary/octet-stream' } })
            .then(resolve)
            .catch(reject)
        })
        .catch(reject)
    })
  }

  const uploadToRezcav = (beatId, unsignedTrackUrl, unsignedImageUrl) => {
    return axios.post(routes.postBeatsUrl, rejectEmptyValues({
      beat_id: beatId,
      producer_user_id: authClient.userId(),
      beat_name: state.trackName,
      audio_file_url: unsignedTrackUrl,
      artwork_url: unsignedImageUrl,
      electronic_signature: state.electronicSignature,
      genre: state.genre,
      bpm: parseInt(state.bpm, 10) || null,
      key: state.key,
      buy_license_url: state.buyLicenseUrl,
    }))
  }

  const handleTrackNameChanged = (event) => {
    setState({ trackName: event.target.value })
  }

  const handleGenreChanged = (value) => {
    setState({ genre: value })
  }

  const handleBpmChanged = (event) => {
    setState({ bpm: event.target.value })
  }

  const handleKeyChanged = (value) => {
    setState({ key: value })
  }

  const handleBuyLicenseUrlChanged = (event) => {
    setState({ buyLicenseUrl: event.target.value })
  }

  const handleElectronicSignatureChanged = (event) => {
    setState({ electronicSignature: event.target.value })
  }

  const handleAudioFileChanged = (audioFile) => {
    setState({ audioFile })
  }

  const handleImageFileChanged = (imageFile) => {
    setState({ imageFile })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (state.submitting) {
      return
    }

    if (state.imageFile == null) {
      Sentry.captureMessage('Image file missing')
      showAlertError('The image file is missing. Please select a valid image file and try again.')
      return
    }

    if (state.audioFile == null) {
      Sentry.captureMessage('Audio file missing')
      showAlertError('The audio file is missing. Please select a valid audio file and try again.')
      return
    }

    const doneSubmitting = () => {
      setState({ submitting: false })
    }

    setState({ submitting: true })
    getUrlBeat().then(response => {
      const {
        beat_id,
        unsigned_track_url,
        signed_track_url,
        unsigned_image_url,
        signed_image_url
      } = response.data
      uploadToAws(signed_track_url, signed_image_url).then(() => {
        uploadToRezcav(
          beat_id,
          unsigned_track_url,
          unsigned_image_url
        ).then(() => {
          resetForm()
          props.router.navigate(routes.dynamic(routes.producerPurchaseBeatBoostPath, beat_id))
        }).catch(error => {
          handleError(error)
          doneSubmitting()
        })
      }).catch(error => {
        handleError(error)
        doneSubmitting()
      })
    }).catch(error => {
      handleError(error)
      doneSubmitting()
    })
  }

  const submitBeat = (
    <Layout>
      <Layout.Header>
        Upload Beat
      </Layout.Header>
      <div className='layout-content'>
        <p className='submit-preface submit-preface-big'>
          For the best chance of having your beat featured, check out our
          <Link to={routes.producerBeatsSubmissionTips}> beat upload tips!</Link>
        </p>
        {requiredFieldsPreface}
        <Form onSubmit={handleSubmit}>
          <div className='submit-track'>
            <div className='submit-track__left'>
              <Form.Item>
                <ImageFilePicker onChange={handleImageFileChanged} />
              </Form.Item>
              <Form.Item>
                <AudioFilePicker onChange={handleAudioFileChanged} />
              </Form.Item>
            </div>
            <div className='submit-track__right'>
              <Form.Item label='Beat Name *'>
                <Input required type='text' value={state.trackName} onChange={handleTrackNameChanged} />
              </Form.Item>
              <Form.Item label='Genre *'>
                <select required translate={'no'} defaultValue='' onChange={(event) => handleGenreChanged(event.target.value)} className='ant-input form-select'>
                  <option disabled value=''></option>
                  {globals.genres.map((genre, index) => {
                    return <option key={index} className='select' value={genre.id}>{genre.value}</option>
                  })}
                </select>
              </Form.Item>
              <Form.Item label='BPM'>
                <Input value={state.bpm} type='number' min='1' max='300' step='1' placeholder='Auto Detect' onChange={handleBpmChanged} />
              </Form.Item>
              <Form.Item label='Key'>
                <select defaultValue='' onChange={(event) => handleKeyChanged(event.target.value)} className='ant-input form-select'>
                  <option value=''>Auto Detect</option>
                  {keyScales.map((keyScale, index) => {
                    return <option key={index} className='select' value={keyScale.id}>{keyScale.value}</option>
                  })}
                </select>
              </Form.Item>
              {buyLicenseUrlFormInput(handleBuyLicenseUrlChanged, state.buyLicenseUrl)}
              <Form.Item>
                {termsOfServiceLabel}
                <Input required type='text' value={state.electronicSignature} onChange={handleElectronicSignatureChanged}/>
              </Form.Item>
                <Form.Button loading={state.submitting}>
                  Upload Beat
                </Form.Button>
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  )
  return (
    <div className='body-container'>
      {withProfileCompleteness(submitBeat)}
    </div>
  )
}

export default withRouter(SubmitBeat)
