import React from 'react'
import { Navigate } from 'react-router-dom'
import * as routes from '../routes'
import { LoginButton } from '../auth'
import authClient from '../auth/authClient'
import { Loading } from '../shared'

class ProducerLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    authClient.initialize(() => {
      const handler = () => { this.setState({ loading: false }) }
      authClient.tryLogin(routes.producerSignInUrl).then(handler).catch(handler)
    })
  }

  render() {
    if (this.state.loading) {
      return (<Loading />)
    } else {
      if (authClient.isLoggedIn()) {
        return <Navigate to={routes.producerBeatsPath} />
      } else {
        const loginProps = {
          providers: ['google', 'facebook', 'apple', 'phone'],
          signInUrl: routes.producerSignInUrl,
          signUpPath: routes.producerSignUpPath,
          afterLoginPath: routes.producerBeatsPath
        }
        return <LoginButton {...loginProps} {...this.props} />
      }
    }
  }
}

export default ProducerLogin
