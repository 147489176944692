import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import Hls from 'hls.js'
import ReactDOM from 'react-dom/client'
import './index.scss'
import * as Sentry from '@sentry/react'
import App from './App'

var environment = 'unknown'
if (/http(s)?:\/\/localhost:\d{4}/.test(window.location.origin)) {
  environment = 'development'
} else if (/http(s)?:\/\/qa\.resonantcavity\.com/.test(window.location.origin)) {
  environment = 'qa'
} else {
  environment = 'production'
}

if (environment !== 'development') {
  Sentry.init({
    dsn: 'https://e147eedf1782492db3042b4e381fb9e9@o407839.ingest.sentry.io/5391059',
    environment: environment,
    ignoreErrors: [
      // Custom ignores
      // VidMate app
      /vid_mate_/,
      // HeyTap browser
      'getReadMode',

      // These are from https://gist.github.com/impressiver/5092952
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
    ],
    ignoreUrls: [
      // These are from https://gist.github.com/impressiver/5092952
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  })
}

// react-player will load hls.js from jsdelivr.net unless we preload it like this
window.Hls = Hls

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
)
