import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import queryString from 'query-string'
import * as routes from '../routes'
import { formatStat, Loading, withRouter } from '../shared'
import { AlertContext } from '../widgets'
import AppStoreBanner from './AppStoreBanner'
import PublicUserLink from './PublicUserLink'
import { AudioPlayerContext } from './AudioPlayerContext'
import likeImg from '../images/like.svg'
import playImg from '../images/play.svg'
import useImg from '../images/use.svg'
import commentImg from '../images/comment.svg'
import playerPauseImg from '../images/player-pause.svg'
import playerPlayImg from '../images/player-play.svg'

const PublicBeatView = (props) => {
  const [loading, setLoading] = useState(true)
  const [beat, setBeat] = useState(null)

  const { handleError } = useContext(AlertContext)
  const { setItemsFromBeats, isItemPlaying, pauseItem, loadAndPlayBeat } = useContext(AudioPlayerContext)

  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (!beat) { return }
    setItemsFromBeats([beat])
  }, [beat])

  const getId = () => queryString.parse(props.router.location.search).id

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.getBeatUrl, getId())
    ).then((response) => {
      setBeat(response.data)
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return loading ? <Loading /> : <>
    <AppStoreBanner />

    <div className='public-view-background'>
      <img src={beat.images['400']} alt='' />
    </div>

    <div className='public-view-body'>
      <div className='position-relative'>
        <div className='public-view-plays-overlay'>
          <img src={playImg} alt='play' draggable='false'/>
          <span>{formatStat(beat.play_count)}</span>
        </div>
        <span className='public-view-play-pause-overlay' onClick={() => isItemPlaying(beat.id) ? pauseItem(beat.id) : loadAndPlayBeat(beat)}>
          <img src={isItemPlaying(beat.id) ? playerPauseImg : playerPlayImg} alt='play' draggable='false'/>
        </span>
        <img className='public-view-artwork' src={beat.images['400']} />
      </div>
      <div className='public-view-item-title'>{beat.beat_name}</div>

      <PublicUserLink user_id={beat.producer_user_id} username={beat.producer_name} user_images={beat.user_profile_images} />

      <div className='public-view-stats'>
        <AudioPlayerContext.Stat image={useImg} value={formatStat(beat.use_count)} />
        <AudioPlayerContext.Stat image={likeImg} value={formatStat(beat.like_count)} />
        <AudioPlayerContext.Stat image={commentImg} value={formatStat(beat.comment_count)} />
      </div>
    </div>
  </>
}

export default withRouter(PublicBeatView)
