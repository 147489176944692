import { useContext, useState } from 'react'
import axios from 'axios'
import { Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import { Loading, withProfileCompleteness } from '../shared'
import { AudioPlayerContext, PostCell, PaginatedList } from '../components'

const ProducerPosts = (props) => {
  const [loading, setLoading] = useState(false)
  const { showAlertConfirm, showToast, handleError } = useContext(AlertContext)
  const { loadAndPlayPost, pauseItem, isItemPlaying } = useContext(AudioPlayerContext)

  const onShare = (post) => {
    navigator.clipboard.writeText(post.share_url).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the track to your clipboard!')
    })
  }

  const onDelete = (post, didHide) => {
    showAlertConfirm({
      title: 'Confirm Track Deletion',
      message: 'Are you sure you want to delete this track? It will no longer be available to others.',
      callback: () => {
        setLoading(true)
        axios.delete(routes.deletePostUrl, {
          data: {
            item_id: post.id
          }
        }).then((response) => {
          didHide(post)
        }).catch((error) => {
          handleError(error)
        }).finally(() => {
          setLoading(false)
        })
      }
    })
  }

  const onTogglePlayPause = (post) => {
    if (!post) { return }
    if (isItemPlaying(post.id)) {
      pauseItem(post.id)
    } else {
      loadAndPlayPost(post)
    }
  }

  if (loading) {
    return <div className='body-container'>
      <Layout>
        <Loading />
      </Layout>
    </div>
  }

  const content = <Layout>
    <Layout.Header>Tracks</Layout.Header>
    <PaginatedList
      key={'post'}
      loadUrl={routes.getPostsPrivateUserUrl}
      cell={PostCell}
      cellItemPropName={'post'}
      cellAdditionalProps={{
        onShare: onShare,
        onDelete: onDelete,
        onPlayPause: (post) => onTogglePlayPause(post),
        isPlaying: (post) => isItemPlaying(post.id),
      }}
    />
  </Layout>

  return (
    <div className='body-container'>
      {withProfileCompleteness(content)}
    </div>
  )
}

export default ProducerPosts
