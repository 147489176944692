import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import authClient from '../auth/authClient'
import {
  allowedImageTypes,
  awsClient,
  cleanText,
  maxNumBioCharacters,
  maxNumBioNewlines,
  Loading,
  validateImageFile,
  rejectEmptyValues,
  withRouter,
} from '../shared'

import cameraImg from '../images/camera.svg'
import profilePicPlaceholder from '../images/profile_pic_placeholder.svg'

const ProducerProfileEdit = (props) => {
  const [state, replaceState] = useState({
    loading: false,
    submitting: false,

    existingProfilePicUrl: null,
    profilePic: null,
    profilePicContents: null,

    username: null,
    bio: null,
    bioValidationText: null,
    socialLinks: null,
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const { showAlert, showAlertConfirm, showAlertError, handleError } = useContext(AlertContext)

  useEffect(() => {
    const {
      username
    } = authClient.currentUser()

    const profileData = (authClient.currentUser() || {}).profile || {}

    const {
      profile_pics,
      bio,
      social_links
    } = profileData

    const existingProfilePicUrl = profile_pics && profile_pics['400']
    const socialLinks = props.isSigningUp ? props.socialLinkTemplates : social_links

    setState({
      existingProfilePicUrl,
      username,
      bio,
      socialLinks
    })
    handleBioChanged(bio || '')
  }, [])

  const getUrlProfile = () => {
    return axios.get(routes.getUrlProfileUrl, {
      params: rejectEmptyValues({
        user_id: authClient.userId(),
        image: cleanText(state.profilePic.name)
      })
    })
  }

  const uploadToAws = (signedPicUrl) => {
    const image = state.profilePic
    return awsClient.put(signedPicUrl, image, { headers: { 'Content-Type': 'binary/octet-stream' } })
  }

  const getProfileDataForSubmission = () => {
    const data = {
      bio: state.bio,
      social_links: state.socialLinks,
    }
    return data
  }

  const updateProfilePic = () => {
    const data = getProfileDataForSubmission()
    if (state.profilePic == null) {
      return Promise.resolve(data)
    }

    return new Promise((resolve, reject) => {
      getUrlProfile().then((response) => {
        const {
          unsigned_pic_url,
          signed_pic_url
        } = response.data
        uploadToAws(signed_pic_url).then(() => {
          const withProfilePic = Object.assign(data, { 'raw_profile_pic': unsigned_pic_url })
          resolve(withProfilePic)
        }).catch((error) => reject(error))
      }).catch((error) => reject(error))
    })
  }

  const handleBioChanged = (newBio) => {
    var remainingNewlines = maxNumBioNewlines - (newBio.match(/\n/g) || []).length
    while (remainingNewlines < 0) {
      newBio = newBio.replace(/\n([^\n]*)$/, '$1')
      remainingNewlines = maxNumBioNewlines - (newBio.match(/\n/g) || []).length
    }
    var remainingChars = maxNumBioCharacters - newBio.length
    if (remainingChars < 0) {
      newBio = newBio.slice(0, maxNumBioCharacters)
      remainingChars = 0
    }
    setState({
      bio: newBio,
      bioValidationText: `${remainingChars} characters remaining`
    })
  }

  const handleProfilePicChanged = (file) => {
    if (!file) {
      return
    }

    validateImageFile(file, (error) => {
      if (error) {
        showAlertError(error)
      } else {
        setState({
          profilePic: file
        })
        const reader = new FileReader()
        reader.onloadend = () => {
          setState({
            profilePicContents: reader.result
          })
        }
        reader.readAsDataURL(file)
      }
    })
  }

  const handleTextChanged = (event, key) => {
    setState({
      [key]: event.target.value
    })
  }

  const handleSocialLinkChanged = (serviceId, value) => {
    state.socialLinks.find((x) => x.service_id === serviceId).value = value
    setState({
      socialLinks: state.socialLinks,
    })
  }

  const doneSubmitting = () => {
    setState({ submitting: false })
  }

  const updateProfile = () => {
    updateProfilePic().then((data) => {
      axios.patch(routes.producerProfileUrl, {
        user_id: authClient.currentUser().user_id,
        username: state.username,
        profile: JSON.stringify(data)
      }).then((response) => {
        authClient.updateUser(response.data)
        props.router.navigate(routes.producerProfilePath)
        window.location.reload()
      }).catch((error) => {
        handleError(error)
      }).finally(() => doneSubmitting())
    }).catch((error) => {
      handleError(error)
      doneSubmitting()
    })
  }

  const handleUpdateProfile = (e) => {
    e.preventDefault()
    setState({ submitting: true })
    if (props.isSigningUp) {
      authClient.signUp(routes.producerSignUpUrl,
        state.username,
        getProfileDataForSubmission()
      ).then(() => updateProfile()).catch((error) => {
        handleError(error)
        doneSubmitting()
      })
    } else {
      updateProfile()
    }
  }

  const handleDeleteAccount = () => {
    showAlertConfirm({
      title: 'Delete Account',
      message: <>
        Your submitted beats will no longer be available to others.<br/><br/>
        Your submitted top tracks will no longer be available to others.<br/><br/>
        You will no longer have access to favorited beats.<br/><br/>
        In-app purchases on the mobile app will not be affected.<br/><br/>
        Recordings in the Library of the mobile app will not be affected.<br/><br/>
      </>,
      callback: () => {
        axios.delete(routes.producerDeleteAccountUrl)
          .then(() => {
            showAlert({
              title: 'Account Deleted',
              message: 'You will now be signed out.',
              callback: () => {
                authClient.logout().finally(() => window.location.pathname = routes.producerRootPath)
              }
            })
          }).catch((error) => {
            handleError(error)
          })
      }
    })
  }

  if (state.loading) { return (<Loading />) }

  const profilePic = <img src={state.profilePicContents || state.existingProfilePicUrl || profilePicPlaceholder} alt='' className='producer-profile-pic' />

  const updateButton = (
    <Form.Button
      htmlType='submit'
      loading={state.submitting}
    >
      { props.isSigningUp ? 'Continue' : 'Update'}
    </Form.Button>
  )

  const deleteAccountButton = props.isSigningUp ? null : (
    /* eslint-disable */
    <>
      {[...Array(4)].map((_, i) => <br key={i} />)}
      <a href='#' onClick={() => handleDeleteAccount()} style={{ color: '#E6005F' }}>
        Delete Account
      </a>
    </>
    /* eslint-enable */
  )

  const updateProfileContent = (
    <Layout>
      <Layout.Header className='producer-profile__header'>
        {props.header || 'Edit Profile'}
      </Layout.Header>
      <div className='layout-content'>
        <Form layout='vertical' onSubmit={handleUpdateProfile}>
          <div className='producer-profile__container'>
            <div className='producer-profile__left'>
              <Form.Item className='producer-profile__img-field'>
                <label htmlFor='producer-profile-image' className='producer-profile__img-label'>
                  {profilePic}
                  <img
                    src={cameraImg}
                    className='producer-profile-pic-camera-icon'
                    alt='Profile'
                  />
                  <div className='producer-profile-pic edit overlay' />
                </label>
                <Input
                  required={state.existingProfilePicUrl === null}
                  type='file'
                  accept={allowedImageTypes}
                  onChange={(event) => handleProfilePicChanged(event.target.files[0])}
                  name='producer-profile-image'
                  id='producer-profile-image'
                  className='producer-file-upload-field'
                />
              </Form.Item>
            </div>
            <div className='producer-profile__right'>
              <br />
              <Form.Item label='Username'>
                <Input
                  required
                  title='Please fill out this field.'
                  type='text'
                  value={state.username}
                  onChange={(event) => handleTextChanged(event, 'username')}
                />
              </Form.Item>
              <Form.Item label='Bio'>
                <Input.TextArea
                  value={state.bio}
                  onChange={(event) => handleBioChanged(event.target.value)}
                  rows={5}
                />
                <label className='characters-remaining'>
                  {state.bioValidationText}
                </label>
              </Form.Item>
              {state.socialLinks && state.socialLinks.map((socialLink, index) => (
                <Form.Item label={socialLink.input_add_new_message} key={index}>
                  <Input
                    value={socialLink.value}
                    before={socialLink.input_prefix}
                    placeholder={socialLink.input_placeholder}
                    onChange={(event) => handleSocialLinkChanged(socialLink.service_id, event.target.value)}
                  />
                </Form.Item>
              ))}
              {updateButton}
            </div>
          </div>
        </Form>
        {deleteAccountButton}
      </div>
    </Layout>
  )

  return (
    <div className='body-container'>
      {updateProfileContent}
    </div>
  )
}

export default withRouter(ProducerProfileEdit)
