import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import './App.scss'
import * as routes from './routes'
import globals from './globals'
import { AlertContextProvider } from './widgets'
import { PrivateRoute } from './auth'
import { Loading } from './shared'
import {
  CookieConsentBar,
  PublicBeatView,
  PublicPostView,
  PublicPlaylistView,
} from './components'
import {
  ProducerSignUp,
  ProducerLogin,
  ProducerBeats,
  ProducerPosts,
  ProducerPlaylists,
  ProducerProfileEdit,
  ProducerProfile,
  SubmissionTips,
  SubmitBeat,
  EditBeat,
  PublicUserView,
  Report,
  PurchaseBeatBoost,
  ProducerBoosts,
  EditPlaylist,
} from './user'
import {
  AdminLogin,
  AdminEditBeat,
  AdminEditPost,
  AdminEditPlaylist,
  AdminCreatorProfile,
  AdminSearch,
  AdminDiscover,
  AdminNewDiscoverItem,
  AdminModeration,
  AdminAnalytics,
  AdminConversation,
} from './admin'
import { AudioPlayerContextProvider } from './components/AudioPlayerContext'
import {
  AdminBeats,
  AdminPosts,
  AdminPlaylists,
  AdminBoosts,
} from './admin/feed'
import { ProducerNavigation } from './user/components'
import { AdminNavigation } from './admin/components'

const PublicView = ({element}) => <>
  <AudioPlayerContextProvider>
    {element}
  </AudioPlayerContextProvider>
</>

const PrivateProducer = ({element}) => <PrivateRoute
  element={
    <>
      <ProducerNavigation />
      <AudioPlayerContextProvider withNavigationSidebar>
        {element}
      </AudioPlayerContextProvider>
    </>
  }
  loginPath={routes.producerSignInUrl}
  unauthedPath={routes.producerRootPath}
/>

const PrivateAdmin = ({element}) => <PrivateRoute
  element={
    <>
      <AdminNavigation />
      <AudioPlayerContextProvider withNavigationSidebar>
        {element}
      </AudioPlayerContextProvider>
    </>
  }
  loginPath={routes.adminAuthUrl}
  unauthedPath={routes.adminRootPath}
/>

const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    globals.load((error) => {
      if (error) {
        console.log(error)
        alert(error)
      } else {
        setLoading(false)
      }
    })
  }, [])

  return (
    <Router>
      <AlertContextProvider>
        <div className='content'>
          <CookieConsentBar />
          {loading ? <Loading /> :
            <Routes>

              {/* PRODUCER  */}
              <Route path={routes.rootPath} element={<Navigate to={routes.producerRootPath} />} />
              <Route path={`${routes.rootPath}index.html`} element={<Navigate to={routes.producerRootPath} />} />
              <Route path={routes.producerRootPath} element={<ProducerLogin/>} />
              <Route path={routes.producerSignUpPath} element={<ProducerSignUp/>} />

              <Route path={routes.producerBeatsPath} element={<PrivateProducer element={<ProducerBeats/>} />} />
              <Route path={routes.producerTopTracksPath} element={<PrivateProducer element={<ProducerPosts/>} />} />
              <Route path={routes.producerPlaylistsPath} element={<PrivateProducer element={<ProducerPlaylists/>} />} />
              <Route path={routes.producerSubmitPath} element={<PrivateProducer element={<SubmitBeat/>} />} />
              <Route path={routes.producerBeatsSubmitPath} element={<PrivateProducer element={<SubmitBeat/>} />} />
              <Route path={routes.producerBeatsEditPath} element={<PrivateProducer element={<EditBeat/>} />} />
              <Route path={routes.producerPlaylistsEditPath} element={<PrivateProducer element={<EditPlaylist/>} />} />
              <Route path={routes.producerProfileEditPath} element={<PrivateProducer element={<ProducerProfileEdit/>} />} />
              <Route path={routes.producerProfilePath} element={<PrivateProducer element={<ProducerProfile/>} />} />
              <Route path={routes.producerPurchaseBeatBoostPath} element={<PrivateProducer element={<PurchaseBeatBoost/>} />} />
              <Route path={routes.producerBeatsSubmissionTips} element={<PrivateProducer element={<SubmissionTips/>} />} />
              <Route path={routes.producerBoostsPath} element={<PrivateProducer element={<ProducerBoosts/>} />} />
              <Route path={routes.producerReportPath} element={<Report/>} />

              <Route path={routes.withoutParams(routes.purchaseBoostDeepLink)} element={<PrivateProducer element={<PurchaseBeatBoost/>} />} />

              {/* ADMIN */}
              <Route path={routes.adminRootPath} element={<AdminLogin/>} />
              <Route path={routes.adminBeatsPath} element={<PrivateAdmin element={<AdminBeats/>} />} />
              <Route path={routes.adminPostsPath} element={<PrivateAdmin element={<AdminPosts/>} />} />
              <Route path={routes.adminPlaylistsPath} element={<PrivateAdmin element={<AdminPlaylists/>} />} />
              <Route path={routes.adminCreatorProfilePath} element={<PrivateAdmin element={<AdminCreatorProfile/>} />} />
              <Route path={routes.adminSearchPath} element={<PrivateAdmin element={<AdminSearch/>} />} />
              <Route path={routes.adminModerationPath} element={<PrivateAdmin element={<AdminModeration/>} />} />
              <Route path={routes.adminDiscoverPath} element={<PrivateAdmin element={<AdminDiscover/>} />} />
              <Route path={routes.adminDiscoverNewPath} element={<PrivateAdmin element={<AdminNewDiscoverItem/>} />} />
              <Route path={routes.adminPostEditPath} element={<PrivateAdmin element={<AdminEditPost/>} />} />
              <Route path={routes.adminBeatsEditPath} element={<PrivateAdmin element={<AdminEditBeat/>} />} />
              <Route path={routes.adminPlaylistEditPath} element={<PrivateAdmin element={<AdminEditPlaylist/>} />} />
              <Route path={routes.adminAnalyticsPath} element={<PrivateAdmin element={<AdminAnalytics/>} />} />
              <Route path={routes.adminBoostListPath} element={<PrivateAdmin element={<AdminBoosts/>} />} />
              <Route path={routes.adminConversationPath} element={<PrivateAdmin element={<AdminConversation/>} />} />

              {/* PUBLIC */}
              <Route path={routes.applinksPostsPath} element={<PublicView element={<PublicPostView/>} />} />
              <Route path={routes.applinksBeatsPath} element={<PublicView element={<PublicBeatView/>} />} />
              <Route path={routes.applinksPublicUserProfilePath} element={<PublicView element={<PublicUserView/>} />} />
              <Route path={routes.applinksPlaylistPath} element={<PublicView element={<PublicPlaylistView/>} />} />

              {/* CATCH ALL */}
              <Route
                path='*'
                element={
                  <>
                    <div className='welcome-title'>404</div>
                    <div>There's nothing here</div>
                  </>
                }
              />
            </Routes>
          }
        </div>
      </AlertContextProvider>
    </Router>
  )
}

export default App
