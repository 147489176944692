import React, { Component } from 'react'
import { Layout } from '../../widgets'
import * as routes from '../../routes'
import { AdminBeatCell } from '../components'
import { NavigationTab, PaginatedList } from '../../components'

class AdminBeats extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'Trending',
    }
  }

  render = () => {
    const tabs = {
      'Trending': <PaginatedList
        key={'trending'}
        loadUrl={routes.adminBeatTrendingUrl}
        loadParams={{}}
        featuredUrl={routes.adminBeatFeaturedUrl}
        notFeaturableUrl={routes.adminBeatNotFeaturableUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
        datePicker={true}
        featureActionUpdateBehavior={'filter'}
      />,
      'New': <PaginatedList
        key={'recently_created'}
        loadUrl={routes.adminBeatNewUrl}
        loadParams={{}}
        featuredUrl={routes.adminBeatFeaturedUrl}
        notFeaturableUrl={routes.adminBeatNotFeaturableUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
        featureActionUpdateBehavior={'filter'}
      />,
      'Featured': <PaginatedList
        key={'recently_featured'}
        loadUrl={routes.adminBeatFeaturedUrl}
        loadParams={{}}
        featuredUrl={routes.adminBeatFeaturedUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
        featureActionUpdateBehavior={'filter'}
      />,
      'Ignored': <PaginatedList
        key={'recently_ignored'}
        loadUrl={routes.adminBeatNotFeaturableUrl}
        loadParams={{}}
        notFeaturableUrl={routes.adminBeatNotFeaturableUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
        featureActionUpdateBehavior={'filter'}
      />,

      'Hot Feed': <PaginatedList
        key={'hot'}
        loadUrl={routes.adminBeatFeedUrl}
        loadParams={{'type': 'all_scored'}}
        featuredUrl={routes.adminBeatFeaturedUrl}
        notFeaturableUrl={routes.adminBeatNotFeaturableUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
      />,
      'Featured Feed': <PaginatedList
        key={'featured'}
        loadUrl={routes.adminBeatFeedUrl}
        loadParams={{'type': 'featured_new'}}
        featuredUrl={routes.adminBeatFeaturedUrl}
        notFeaturableUrl={routes.adminBeatNotFeaturableUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
      />,
      'New Feed': <PaginatedList
        key={'new'}
        loadUrl={routes.adminBeatFeedUrl}
        loadParams={{'type': 'all_new'}}
        featuredUrl={routes.adminBeatFeaturedUrl}
        notFeaturableUrl={routes.adminBeatNotFeaturableUrl}
        cell={AdminBeatCell}
        cellItemPropName={'beat'}
      />,
    }

    return (
      <div className='body-container'>
        <Layout>
          <Layout.Header>Beats</Layout.Header>
          <div className='moderator-content'>

            <div className='navigation-tabs'>
              {Object.keys(tabs).map((tab, i) => (
                <NavigationTab
                  key={i}
                  text={tab}
                  isActive={this.state.tab === tab}
                  onClick={() => {
                    if (this.state.tab !== tab) {
                      this.setState({ tab: tab })
                    }
                  }}
                />
              ))}
            </div>

            {tabs[this.state.tab]}

          </div>
        </Layout>
      </div>
    )
  }
}

export default AdminBeats
