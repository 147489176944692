import React from 'react'
import {
  niceIso8601String,
  formatStat,
} from '../../shared'
import AdminEditItemLink from './AdminEditItemLink'
import UserLink from './UserLink'
import { Cell } from '../../components'

const AdminPlaylistCell = ({playlist, noline, children}) => {
  return (
    <Cell noline={noline}>
      <Cell.Image src={playlist.images && playlist.images['400']} alt='' large />
      <Cell.Body>
        <Cell.Line>
          <Cell.LineTitle>
            <AdminEditItemLink item_name={playlist.name} item_type={'PLAYLIST'} item_id={playlist.id} />
          </Cell.LineTitle>
          {playlist.is_featured && <Cell.LineFeatured />}
        </Cell.Line>

        <Cell.Line>
          <UserLink user_id={playlist.user.user_id} username={playlist.user.username} />
        </Cell.Line>

        <Cell.Line>
          <div>Created {niceIso8601String(playlist.created_at)}</div>
        </Cell.Line>

        <Cell.Line>
          <div>Updated {niceIso8601String(playlist.updated_at)}</div>
        </Cell.Line>

        {<Cell.Line>
          {<Cell.ItemCount>{formatStat(playlist.item_count)}</Cell.ItemCount>}
          {<Cell.PlayCount>{formatStat(playlist.play_count)}</Cell.PlayCount>}
          {<Cell.LikeCount>{formatStat(playlist.like_count)}</Cell.LikeCount>}
        </Cell.Line>}
      </Cell.Body>

      {children}
    </Cell>)
}

AdminPlaylistCell.ModerationActions = ({pendingCount, reviewedCount, onDetails, onAllow, onBlock}) => (
  <Cell.Actions>
    <Cell.Button onClick={onDetails}>
      {pendingCount} / {pendingCount+reviewedCount}
    </Cell.Button>
    <Cell.Button onClick={onAllow}>
      Allow
    </Cell.Button>
    <Cell.Button onClick={onBlock}>
      Block
    </Cell.Button>
  </Cell.Actions>
)

AdminPlaylistCell.FeatureActions = ({playlist, onFeatureClick, onIgnoreClick}) => {
  var actions = null
  if (playlist.is_featured) {
    actions = onFeatureClick && <Cell.Button onClick={() => onFeatureClick(playlist)}>Unfeature</Cell.Button>
  } else if (!playlist.is_featurable) {
    actions = onIgnoreClick && <Cell.Button onClick={() => onIgnoreClick(playlist)}>Unignore</Cell.Button>
  } else {
    actions = <>
      {onFeatureClick && <Cell.Button onClick={() => onFeatureClick(playlist)}>Feature</Cell.Button>}
      {onIgnoreClick && <Cell.Button onClick={() => onIgnoreClick(playlist)}>Ignore</Cell.Button>}
    </>
  }
  return <Cell.Actions>
    {actions}
  </Cell.Actions>
}

AdminPlaylistCell.EditActions = ({playlist, onFeature, onBlockImage}) => <Cell.Actions>
  {onFeature && <Cell.Button onClick={onFeature}>{playlist.is_featured ? 'Unfeature' : 'Feature'}</Cell.Button>}
  {onBlockImage && <Cell.Button onClick={onBlockImage}>{playlist.is_image_blocked ? 'Unblock Image' : 'Block Image'}</Cell.Button>}
</Cell.Actions>

export default AdminPlaylistCell
