import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Form, Input, Layout, AlertContext } from '../widgets'
import * as routes from '../routes'
import globals from '../globals'
import FilePickerOverlay from '../components/FilePickerOverlay'
import {
  requiredFieldsPreface,
  withProfileCompleteness,
  Loading,
  keyScales,
  buyLicenseUrlFormInput,
  withRouter,
} from '../shared'

const EditBeat = (props) => {
  const [state, replaceState] = useState({
    loading: true,
    submitting: false,
    beat: {},
    buyLicenseUrl: null,

    genre_id: null,
    bpm: null,
    key: null,
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const { handleError } = useContext(AlertContext)

  useEffect(() => {
    loadBeat()
  }, [])

  const getBeatId = () => {
    return props.router.params.beatId
  }

  const loadBeat = () => {
    axios.get(
      routes.dynamic(routes.getBeatUrl, getBeatId())
    ).then((response) => {
      setState({
        beat: response.data,
        buyLicenseUrl: response.data.buy_license_url,

        genre_id: response.data.genre_id,
        bpm: parseInt(response.data.bpm, 10),
        key: response.data.key,
      })
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setState({ loading: false })
    })
  }

  const handleEditBeat = (e) => {
    e.preventDefault()

    const doneSubmitting = () => {
      setState({ submitting: false })
    }

    setState({ submitting: true })
    axios.patch(routes.dynamic(routes.getBeatUrl, getBeatId()),
      {
        genre: state.genre_id,
        bpm: state.bpm,
        key: state.key,
        buy_license_url: state.buyLicenseUrl,
      }
    ).then(() => {
      props.router.navigate(routes.producerBeatsPath)
    }).catch(error => {
      handleError(error)
      doneSubmitting()
    }).finally(() => {
      doneSubmitting()
    })
  }

  const handleBuyLicenseUrlChanged = (event) => {
    setState({ buyLicenseUrl: event.target.value })
  }

  const handleChange = (key, value) => {
    setState({ [key]: value })
  }

  if (state.loading) {
    return (<Loading />)
  }

  const submitBeat = (
    <Layout>
      <Layout.Header>
        Edit Beat
      </Layout.Header>
      <div className='layout-content'>
        {requiredFieldsPreface}
        <Form layout='vertical' onSubmit={handleEditBeat}>
          <div className='submit-track'>
            <div className='submit-track__left'>
              <Form.Item>
                <FilePickerOverlay
                  readOnly={true}
                  inputName='image-file'
                  title='Cover Image *'
                  image={state.beat.images && state.beat.images['400']}
                />
              </Form.Item>
            </div>
            <div className='submit-track__right'>
              <Form.Item label='Audio'>
                <audio
                  controls
                  className='float-left'
                  src={state.beat.audio_file_url}
                  style={{ height: '30px' }}
                />
              </Form.Item>
              <Form.Item label='Beat Name'>
                <Input readOnly={true} disabled={true} type='text' value={state.beat.beat_name} />
              </Form.Item>
              <Form.Item label='Genre *'>
                <select
                  required
                  translate={'no'}
                  value={state.genre_id}
                  onChange={(event) => handleChange('genre_id', event.target.value)}
                  className='ant-input form-select'
                >
                  {globals.genres.map((genre, index) => {
                    return <option key={index} className='select' value={genre.id}>{genre.value}</option>
                  })}
                </select>
              </Form.Item>
              <Form.Item label='BPM'>
                <Input
                  value={state.bpm}
                  onChange={(event) => handleChange('bpm', event.target.value ? parseInt(event.target.value, 10) : null)}
                  type='number'
                  min='1'
                  max='300'
                  step='1'
                  placeholder='Auto Detect'
                />
              </Form.Item>
              <Form.Item label='Key'>
                <select
                  translate={'no'}
                  value={state.key}
                  onChange={(event) => handleChange('key', event.target.value)}
                  className='ant-input form-select'
                  placeholder={'Auto Detect'}
                >
                  <option value=''>Auto Detect</option>
                  {keyScales.map((keyScale, index) => {
                    return <option key={index} className='select' value={keyScale.id}>{keyScale.value}</option>
                  })}
                </select>
              </Form.Item>
              {buyLicenseUrlFormInput(handleBuyLicenseUrlChanged, state.buyLicenseUrl)}
              <Form.Button
                htmlType='submit'
                loading={state.submitting}
              >
                Save
              </Form.Button>
            </div>
          </div>
        </Form>
      </div>
    </Layout>
  )
  return (
    <div className='body-container'>
      {withProfileCompleteness(submitBeat)}
    </div>
  )
}

export default withRouter(EditBeat)
