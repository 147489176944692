import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../routes'
import {
  niceIso8601Time,
  formatStat,
  niceBlockedMessage,
} from '../shared'
import Cell from './Cell'
import PublicItemLink from './PublicItemLink'

const PlaylistCell = ({audience, playlist, hideName, onShare, onEdit, onDelete}) => {
  const actions = []
  if (onDelete) { actions.unshift({ title: 'Delete', onClick: () => { onDelete(playlist) } }) }
  if (!playlist.is_blocked) {
    if (onShare) { actions.unshift({ title: 'Copy Link', onClick: () => { onShare(playlist) } }) }
  }

  return <Cell>
    <Cell.Image src={playlist.images['100']} alt='' />
    <Cell.Body>

      {!hideName && <Cell.Line>
        {audience === 'public' ?
          <PublicItemLink name={playlist.name} type={'PLAYLIST'} id={playlist.id} /> :
          <Cell.LineTitle><Link to={routes.dynamic(routes.producerPlaylistsEditPath, playlist.id)}>{playlist.name}</Link></Cell.LineTitle>
        }
        {playlist.is_blocked && <Cell.LineImportant>{niceBlockedMessage(playlist.is_blocked)}</Cell.LineImportant>}
        {!playlist.is_blocked && playlist.is_featured && <Cell.LineFeatured />}
      </Cell.Line>}

      <Cell.Line>
        <div>{niceIso8601Time(playlist.created_at)}</div>
      </Cell.Line>

      <Cell.Line>
        {<Cell.ItemCount>{formatStat(playlist.item_count)}</Cell.ItemCount>}
        {<Cell.PlayCount>{formatStat(playlist.play_count)}</Cell.PlayCount>}
        {<Cell.LikeCount>{formatStat(playlist.like_count)}</Cell.LikeCount>}
      </Cell.Line>

    </Cell.Body>
    {actions.length > 0 && <Cell.OverflowButton actions={actions} />}
  </Cell>
}

export default PlaylistCell
