import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/remote-config'
import axios from 'axios'
import * as Sentry from '@sentry/react'

class AuthClient {
  constructor() {
    this.initialized = false
    this.isGoogleAvailable = false
    this.state = {
      user: {}
    }
  }

  initialize(callback) {
    if (this.initialized) {
      callback()
      return
    }
    this.initialized = true

    // configure firebase
    if (/http(s)?:\/\/(api|voloco|beats).resonantcavity.com/.test(window.location.origin)) {
      firebase.initializeApp({
        apiKey: 'AIzaSyDKwg7szD3Sfg17cCcwhJwlJKt3J-HVMqk',
        authDomain: 'voloco-156320.firebaseapp.com',
        projectId: 'voloco-156320',
        appId: '1:73035618530:web:7db48d4d628fc0aa2a66bb',
      })
    } else {
      firebase.initializeApp({
        apiKey: 'AIzaSyAKWi76Z7EBLRdOrPg8zwcO5Ye5SxUsceg',
        authDomain: 'voloco-dev.firebaseapp.com',
        projectId: 'voloco-dev',
        appId: '1:173575683994:web:3fbc5e3ced3eaee13b0423'
      })
    }

    // setup remote config
    this.remoteConfig = firebase.remoteConfig()
    this.remoteConfig.defaultConfig = {
      'boost_beat_sku': 'boost_beat_a'
    }
    this.remoteConfig.fetchAndActivate()
      .catch((err) => {
        Sentry.captureException(err)
      })

    // setup some auth stuff
    if (/http(s)?:\/\/localhost:\d{4}/.test(window.location.origin)) {
      firebase.auth().useEmulator('http://localhost:9099')
    }
    firebase.auth().useDeviceLanguage()

    axios.get(
      'https://apis.google.com/js/api.js'
    ).then((response) => {
      this.isGoogleAvailable = true
    }).catch((error) => {
      this.isGoogleAvailable = false
    }).finally(callback)
  }

  currentUser() {
    return this.state.user
  }

  userId() {
    return this.state.user.user_id
  }

  setUserProfile(profile) {
    this.state.user.profile = profile
  }

  updateUser(user) {
    this.state.user = {
      user_id: user.user_id || this.userId(),
      username: user.username,
      profile: user.profile
    }
  }

  tryLogin(authUrl) {
    if (this.isLoggedIn()) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        this.loginToRezcav(user, authUrl).then(resolve).catch(reject).finally(unsubscribe)
      })
    })
  }

  isFirebaseAuthenticated() {
    return this.initialized && !!firebase.auth().currentUser;
  }

  isLoggedIn() {
    return !!firebase.auth().currentUser && !!this.userId()
  }

  logout() {
    return new Promise((resolve, reject) => {
      axios.interceptors.request.eject(this.state.authInterceptor)
      this.state = {
        user: {}
      }
      firebase.auth().signOut().then(resolve).catch((error) => {
        reject(error)
      })
    })
  }

  loginToRezcav(user, authUrl) {
    if (!user || !authUrl) {
      return Promise.reject()
    }
    if (user && this.userId()) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      user.getIdToken(false).then((firebaseToken) => {
        axios.post(authUrl, {
          token: firebaseToken,
          email: user.email, // required for admin login
          name: user.displayName // required for admin login
        }).then((res) => {
          const { id, user_id, token, username, profile } = res.data
          this.updateUser({
            user_id: id || user_id,
            username: username,
            profile: profile
          })
          this.state.authInterceptor = axios.interceptors.request.use((config) => {
            config.headers = Object.assign({}, config.headers, { 'Authorization': `Bearer ${token}` })
            return config
          })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    })
  }

  signUp(signUpUrl, username, profile) {
    return new Promise((resolve, reject) => {
      firebase.auth().currentUser.getIdToken(false).then((firebaseToken) => {
        axios.post(signUpUrl, {
          token: firebaseToken,
          username: username,
          profile: JSON.stringify(profile)
        }).then((res) => {
          const { user_id, token, username, profile } = res.data
          this.updateUser({
            user_id: user_id,
            username: username,
            profile: profile
          })
          this.state.authInterceptor = axios.interceptors.request.use((config) => {
            config.headers = Object.assign({}, config.headers, { 'Authorization': `Bearer ${token}` })
            return config
          })
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    })
  }

  remoteConfigValue(key) {
    return this.remoteConfig.getValue(key).asString()
  }
}

export default new AuthClient()
