import { useContext, useState } from 'react'
import axios from 'axios'
import * as routes from '../../routes'
import {
  withRouter,
} from '../../shared'
import PaginatedList from '../../components/PaginatedList'
import AdminBeatCell from './AdminBeatCell'
import AdminPostCell from './AdminPostCell'
import AdminUserCell from './AdminUserCell'
import AdminCommentCell from './AdminCommentCell'
import AdminPlaylistCell from './AdminPlaylistCell'
import ItemReportCell from './ItemReportCell'
import FilterPicker from './FilterPicker'
import { AlertContext } from '../../widgets'

const ItemReportAuditCell = ({item, onDetails, onAllow, onBlock}) => {
  if (item.type === 'BEAT') {
    return <AdminBeatCell beat={item.reportable}>
      <AdminBeatCell.ModerationActions
        pendingCount={item.pending_count}
        reviewedCount={item.reviewed_count}
        onDetails={onDetails}
        onAllow={onAllow}
        onBlock={onBlock}
      />
    </AdminBeatCell>
  } else if (item.type === 'POST') {
    return <AdminPostCell post={item.reportable}>
      <AdminPostCell.ModerationActions
        pendingCount={item.pending_count}
        reviewedCount={item.reviewed_count}
        onDetails={onDetails}
        onAllow={onAllow}
        onBlock={onBlock}
      />
    </AdminPostCell>
  } else if (item.type === 'USER') {
    return <AdminUserCell user={item.reportable}>
      <AdminUserCell.ModerationActions
        pendingCount={item.pending_count}
        reviewedCount={item.reviewed_count}
        onDetails={onDetails}
        onAllow={onAllow}
        onBan={onBlock}
      />
    </AdminUserCell>
  } else if (item.type === 'COMMENT') {
    return <AdminCommentCell comment={item.reportable}>
      <AdminCommentCell.ModerationActions
        pendingCount={item.pending_count}
        reviewedCount={item.reviewed_count}
        onDetails={onDetails}
        onAllow={onAllow}
        onBlock={onBlock}
      />
    </AdminCommentCell>
  } else if (item.type === 'PLAYLIST') {
    return <AdminPlaylistCell playlist={item.reportable}>
      <AdminPlaylistCell.ModerationActions
        pendingCount={item.pending_count}
        reviewedCount={item.reviewed_count}
        onDetails={onDetails}
        onAllow={onAllow}
        onBlock={onBlock}
      />
    </AdminPlaylistCell>
  }
}

const ItemReportAuditTable = (props) => {
  const [state, replaceState] = useState({
    itemType: 'USER',
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const { handleError, showAlert } = useContext(AlertContext)

  const onDetails = (item) => {
    showAlert({
      title: 'Report Details',
      message: <PaginatedList
        loadUrl={routes.adminModerationReportItemUrl}
        loadParams={{
          item_id: item.id,
          item_type: item.type,
        }}
        cell={ItemReportCell}
        cellItemPropName={'report'}
      />,
      wide: true,
    })
  }

  const onAllow = (item, didHide) => {
    axios.put(routes.adminModerationReportUpdateUrl, {
      item_id: item.id,
      item_type: item.type,
      action: 'ALLOW',
    }).then(() => {
      didHide(item)
    }).catch(handleError)
  }

  const onBlock = (item, didHide) => {
    if (item.type === 'USER') {
      window.open(routes.dynamic(routes.adminCreatorProfilePath, item.reportable.user_id))
    } else {
      axios.put(routes.adminModerationReportUpdateUrl, {
        item_id: item.id,
        item_type: item.type,
        action: 'BLOCK',
      }).then(() => {
        didHide(item)
      }).catch(handleError)
    }
  }

  const ItemReportAuditWrapperCell = ({item, didHide}) => {
    return <div key={item.id} className='copyright-audit-element'>
      <ItemReportAuditCell
        item={item}
        onDetails={() => onDetails(item)}
        onAllow={() => onAllow(item, didHide)}
        onBlock={() => onBlock(item, didHide)}
      />
    </div>
  }

  const itemTypeFilter = <FilterPicker.ReportableType
    onChange={(values) => setState({ itemType: values[0] })}
    defaultValues={[state.itemType]}
    singleChoice={true}
  />

  return (
    <>
    <div className='copyright-audit-filters'>
      {itemTypeFilter}
    </div>
    <PaginatedList
      key={`itemReport-${state.itemType}`}
      loadUrl={routes.adminModerationReportAuditUrl}
      loadParams={{
        item_type: state.itemType,
      }}
      cell={ItemReportAuditWrapperCell}
      cellItemPropName={'item'}
    />
    </>
  )
}

export default withRouter(ItemReportAuditTable)
