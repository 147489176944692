import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AlertContext, Layout } from '../widgets'
import * as routes from '../routes'
import authClient from '../auth/authClient'
import { ProducerProfileContent } from './components'

const ProducerProfile = () => {
  const user = authClient.currentUser()

  const { showToast } = useContext(AlertContext)

  const onShare = () => {
    navigator.clipboard.writeText(`${window.location.origin}${routes.dynamic(routes.applinksPublicUserProfileLink, user.user_id)}`).then(() => {
      showToast('Link copied to clipboard.')
    }, (err) => {
      showToast('Could not copy the track to your clipboard!')
    })
  }

  return <div className='body-container'>
    <Layout className='profile-view'>
      <Layout.Header className='producer-profile__header'>
        Profile

        <div className='flex-row'>
          <Link className='btn--white' onClick={onShare}>Copy Link</Link>
          <Link className='btn--white' to={routes.producerProfileEditPath}>Edit</Link>
        </div>
      </Layout.Header>
      <ProducerProfileContent username={user.username} profile={user.profile} />
    </Layout>
  </div>
}

export default ProducerProfile
