import { useState } from 'react'
import { Layout } from '../widgets'
import * as routes from '../routes'
import {
  AdminUserCell,
  ItemBlockAuditTable,
  ItemReportAuditTable,
  FilterPicker,
} from './components'
import { NavigationTab, PaginatedList } from '../components'

const AdminModeration = (props) => {
  const [userBanReporterType, setUserBanReporterType] = useState('ADMIN')
  const [state, replaceState] = useState({
    loading: false,
    activeTab: 'reports',
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const handleChange = (key, value) => {
    if (state[key] === value) {
      return
    }
    setState({ [key]: value })
  }

  return (
    <div className='body-container'>
      <Layout>
        <div className='navigation-tabs'>
          <NavigationTab
            isActive={state.activeTab === 'reports'}
            onClick={() => { handleChange('activeTab', 'reports') }}
            text='Reports'
          />
          <NavigationTab
            isActive={state.activeTab === 'blocks'}
            onClick={() => { handleChange('activeTab', 'blocks') }}
            text='Blocks'
          />
          <NavigationTab
            isActive={state.activeTab === 'bans'}
            onClick={() => { handleChange('activeTab', 'bans') }}
            text='Bans'
          />
        </div>

        {state.activeTab === 'reports' && <>
          <div className='moderator-content'>
            <ItemReportAuditTable />
          </div>
        </>}

        {state.activeTab === 'blocks' && <>
          <div className='moderator-content'>
            <ItemBlockAuditTable />
          </div>
        </>}

        {state.activeTab === 'bans' && <>
          <div className='moderator-content'>
            <div className='copyright-audit-filters'>
              <FilterPicker.UserBanReporterType
                onChange={(values) => setUserBanReporterType(values[0])}
                defaultValues={[userBanReporterType]}
              />
            </div>
            <PaginatedList
              key={`bans-${userBanReporterType}`}
              loadUrl={routes.adminModerationUserBanUrl}
              loadParams={{
                'type': userBanReporterType,
              }}
              cell={AdminUserCell}
              cellItemPropName={'user'}
            />
          </div>
        </>}
        </Layout>
    </div>
  )
}

export default AdminModeration
