import * as moment from 'moment'

const DatePicker = ({name, min, start, onChange}) => {
  return <div className='flex-row margin-vertical-small'>
    {name}
    <input
      required
      type='date'
      value={start.format('YYYY-MM-DD')}
      min={moment(min).utc().format('YYYY-MM-DD')}
      max={moment().utc().endOf('day').format('YYYY-MM-DD')}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
}

export default DatePicker
