import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { Form, Input, Layout, AlertContext } from '../widgets'
import { ImageFilePicker } from '../components'
import { Search } from './components'
import * as routes from '../routes'
import {
  awsClient,
  rejectEmptyValues,
  withRouter,
} from '../shared'

// Form components

const DiscoverNewFormHeader = ({title, children}) => (
  <>
    <Layout.Header>{`Discover / New ${title}`}</Layout.Header>
    <div className='layout-content'>
      {children}
    </div>
  </>
)

const SubmitFormButton = ({isSubmitting, label}) => (
  <Form.Button
    htmlType='submit'
    loading={isSubmitting()}
    className='float-right'
  >
    Create
  </Form.Button>
)

const SelectionFormItem = ({setState, stateKey, label, required, options}) => {
  const onChange = (event) => {
    const value = event.target.value
    setState({ [stateKey]: value === '' ? null : value })
  }

  return (
    <Form.Item label={label}>
      <select defaultValue='' required={required} className='ant-input form-select' onChange={onChange} >
        <option disabled={required} value=''/>
        {options.map((option, index) => {
          return <option key={index} value={option['key']}>{option['value']}</option>
        })}
      </select>
    </Form.Item>
  )
}

const TextFormItem = ({setState, stateKey, label, children}) => (
    <Form.Item label={label}>
      {children}
      <Input
        type='text'
        onChange={(event) => setState({ [stateKey]: event.target.value })}
      />
    </Form.Item>
)

const ImageFormItem = ({onChange}) => (
  <Form.Item>
    <ImageFilePicker onChange={onChange} />
  </Form.Item>
)

// Forms for each discover item type

const ShowcaseForm = ({state, setState, props, itemType, stringOptions, linkOptions}) => {

  const { showAlertError, handleError } = useContext(AlertContext)

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!!state.untranslatedTitle && !!state.titleKey) {
      showAlertError('Title cannot be both translated and untranslated!')
      return
    }

    if (!!state.untranslatedBody && !!state.bodyKey) {
      showAlertError('Body cannot be both translated and untranslated!')
      return
    }

    const body = state.untranslatedBody || state.bodyKey
    if (!!body === !!state.buttonKey) {
      showAlertError('Either Body or Button must be present, and not both!')
      return
    }

    const catchClause = (error) => {
      handleError(error)
      setState({ submitting: false })
    }

    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, 'showcase'),
      rejectEmptyValues({
        metadata: {
          title_key: state.titleKey,
          body_key: state.bodyKey,
          button_key: state.buttonKey,
          untranslated_title: state.untranslatedTitle,
          untranslated_body: state.untranslatedBody,
          link: state.link,
        }
      })
    ).then(response => {
      const uploadImageUrl = response.data.image
      const { imageFile } = state
      awsClient.put(uploadImageUrl, imageFile, {
        headers: { 'Content-Type': 'binary/octet-stream' }
      }).then(() => {
        setState({ submitting: false })
        props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
      }).catch(catchClause)
    }).catch(catchClause)
  }

  return (
    <Form layout='vertical' onSubmit={handleSubmit}>
      <div className='submit-track'>
        <div className='submit-track__left'>
          <ImageFormItem onChange={(imageFile) => setState({ imageFile })} />
        </div>
        <div className='submit-track__right'>
          <Form.Item label='Title'>
            <div className='ant-form-item-label'>
              <label className='terms-of-service-container'>
                <div>Translated</div>
              </label>
            </div>
            <SelectionFormItem setState={setState} stateKey={'titleKey'} label={null} required={false} options={stringOptions} />
            <h6>-- or --</h6>
            <div className='ant-form-item-label'>
              <label className='terms-of-service-container'>
                <div>Untranslated, for things like YouTube videos</div>
              </label>
            </div>
            <Input
              type='text'
              onChange={(event) => setState({ 'untranslatedTitle': event.target.value })}
            />
          </Form.Item>

          <Form.Item label='Body'>
            <div className='ant-form-item-label'>
              <label className='terms-of-service-container'>
                <div>Translated</div>
              </label>
            </div>
            <SelectionFormItem setState={setState} stateKey={'bodyKey'} label={null} required={false} options={stringOptions} />
            <h6>-- or --</h6>
            <div className='ant-form-item-label'>
              <label className='terms-of-service-container'>
                <div>Untranslated, for things like usernames</div>
              </label>
            </div>
            <Input
              type='text'
              onChange={(event) => setState({ 'untranslatedBody': event.target.value })}
            />
          </Form.Item>

          <SelectionFormItem setState={setState} stateKey={'buttonKey'} label={'Button'} required={false} options={stringOptions} />
          <TextFormItem setState={setState} stateKey={'link'} label={'Action Link'}>
            <div className='ant-form-item-label'>
              <label className='terms-of-service-container'>
                <div>This can be a regular url or a custom Voloco app link.</div>
                <div>For a custom Voloco app link, copy/paste one of the following templates:</div>
                <pre/>
                {linkOptions.map((linkOption, index) => (
                  <pre key={index}>{linkOption}</pre>
                ))}
              </label>
            </div>
          </TextFormItem>
        </div>
      </div>
      <SubmitFormButton isSubmitting={() => state['submitting']} />
    </Form>
  )
}

const BrowseAllForm = ({state, setState, props, itemType, stringOptions, linkOptions}) => {

  const { handleError } = useContext(AlertContext)

  const handleSubmit = (e) => {
    e.preventDefault()

    const catchClause = (error) => {
      handleError(error)
      setState({ submitting: false })
    }

    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, 'browse_all'),
      rejectEmptyValues({
        metadata: {
          title_key: state.titleKey,
          link: state.link,
        }
      })
    ).then(response => {
      const uploadImageUrl = response.data.image
      const { imageFile } = state
      awsClient.put(uploadImageUrl, imageFile, {
        headers: { 'Content-Type': 'binary/octet-stream' }
      }).then(() => {
        setState({ submitting: false })
        props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
      }).catch(catchClause)
    }).catch(catchClause)
  }

  return (
    <Form layout='vertical' onSubmit={handleSubmit}>
      <div className='submit-track'>

        <div className='submit-track__left'>
          <ImageFormItem onChange={(imageFile) => setState({ imageFile })} />
        </div>

        <div className='submit-track__right'>
          <SelectionFormItem setState={setState} stateKey={'titleKey'} label={'Title *'} required={true} options={stringOptions} />

          <TextFormItem setState={setState} stateKey={'link'} label={'Action Link'}>
            <div className='ant-form-item-label'>
              <label className='terms-of-service-container'>
                <div>This can be a regular url or a custom Voloco app link.</div>
                <div>For a custom Voloco app link, copy/paste one of the following templates:</div>
                <pre/>
                {linkOptions.map((linkOption, index) => (
                  <pre key={index}>{linkOption}</pre>
                ))}
              </label>
            </div>
          </TextFormItem>
        </div>
      </div>
      <SubmitFormButton isSubmitting={() => state['submitting']} />
    </Form>
  )
}

const EffectImageForm = ({state, setState, props, itemType}) => {

  const { handleError } = useContext(AlertContext)

  const handleSubmit = (e) => {
    e.preventDefault()

    const catchClause = (error) => {
      handleError(error)
      setState({ submitting: false })
    }

    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, itemType), {
      metadata: {} // intentionally empty
    }).then(response => {
      const uploadImageUrl = response.data.image
      const { imageFile } = state
      awsClient.put(uploadImageUrl, imageFile, {
        headers: { 'Content-Type': 'binary/octet-stream' }
      }).then(() => {
        setState({ submitting: false })
        props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
      }).catch(catchClause)
    }).catch(catchClause)
  }

  return (
    <Form layout='vertical' onSubmit={handleSubmit}>
      <div className='submit-track'>
        <div className='submit-track__right'>
          <ImageFormItem onChange={(imageFile) => setState({ imageFile })} />
        </div>
      </div>
      <SubmitFormButton isSubmitting={() => state['submitting']} />
    </Form>
  )
}

const EffectForm = ({state, setState, props, stringOptions, itemType}) => {

  const { handleError } = useContext(AlertContext)

  const handleSubmit = (e) => {
    e.preventDefault()

    const catchClause = (error) => {
      handleError(error)
      setState({ submitting: false })
    }

    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, itemType), {
      id: state.idKey,
      metadata: {} // intentionally empty
    }).then(response => {
      setState({ submitting: false })
      props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
    }).catch(catchClause)
  }

  return (
    <Form layout='vertical' onSubmit={handleSubmit}>
      <div className='submit-track'>
        <div className='submit-track__right'>
          <SelectionFormItem setState={setState} stateKey={'idKey'} label={'Effect *'} required={true} options={stringOptions} />
        </div>
      </div>
      <SubmitFormButton isSubmitting={() => state['submitting']} />
    </Form>
  )
}

const GenreForm = ({state, setState, props, stringOptions, itemType}) => {

  const { handleError } = useContext(AlertContext)

  const handleSubmit = (e) => {
    e.preventDefault()

    const catchClause = (error) => {
      handleError(error)
      setState({ submitting: false })
    }

    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, itemType), {
      id: state.idKey,
      metadata: {} // intentionally empty
    }).then(response => {
      const uploadImageUrl = response.data.image
      const { imageFile } = state
      awsClient.put(uploadImageUrl, imageFile, {
        headers: { 'Content-Type': 'binary/octet-stream' }
      }).then(() => {
        setState({ submitting: false })
        props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
      }).catch(catchClause)
    }).catch(catchClause)
  }

  return (
    <Form layout='vertical' onSubmit={handleSubmit}>
      <div className='submit-track'>
        <div className='submit-track__left'>
          <ImageFormItem onChange={(imageFile) => setState({ imageFile })} />
        </div>
        <div className='submit-track__right'>
          <SelectionFormItem setState={setState} stateKey={'idKey'} label={'Genre *'} required={true} options={stringOptions} />
        </div>
      </div>
      <SubmitFormButton isSubmitting={() => state['submitting']} />
    </Form>
  )
}

const ArtistForm = ({setState, props, itemType}) => {

  const { handleError } = useContext(AlertContext)

  const handleCreate = (user) => {
    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, itemType), {
      id: user.user_id,
      metadata: {} // intentionally empty
    }).then(response => {
      setState({ submitting: false })
      props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
    }).catch((error) => {
      handleError(error)
      setState({ submitting: false })
    })
  }

  return (
    <Search
      tabs={['users']}
      defaultCreatorTypes={['artist']}
      userRowActionTitle={'Create'}
      userRowActionOnClick={(user) => handleCreate(user)}
    />
  )
}

const ProducerForm = ({setState, props, itemType}) => {

  const { handleError } = useContext(AlertContext)

  const handleCreate = (user) => {
    setState({ submitting: true })
    axios.post(routes.dynamic(routes.adminDiscoverCreateUrl, itemType), {
      id: user.user_id,
      metadata: {} // intentionally empty
    }).then(response => {
      setState({ submitting: false })
      props.router.navigate(routes.dynamic(routes.adminDiscoverPath, itemType))
    }).catch((error) => {
      handleError(error)
      setState({ submitting: false })
    })
  }

  return (
    <Search
      tabs={['users']}
      defaultCreatorTypes={['producer']}
      userRowActionTitle={'Create'}
      userRowActionOnClick={(user) => handleCreate(user)}
    />
  )
}

const AdminNewDiscoverItem = (props) => {
  const [state, replaceState] = useState({
    loading: true,

    // form options

    stringOptions: [],
    linkOptions: [],

    // form selections

    imageFile: null,
    imageFileContents: null,

    idKey: null,
    titleKey: null,
    bodyKey: null,
    buttonKey: null,
    untranslatedTitle: null,
    untranslatedBody: null,

    link: null,
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  const itemType = () => {
    return props.router.params.type
  }

  const { handleError } = useContext(AlertContext)

  useEffect(() => {
    axios.get(
      routes.dynamic(routes.adminDiscoverOptionsUrl, itemType())
    ).then((response) => {
      // can't destructure since some discover types don't have these options
      const links = response.data.links
      const strings = response.data.strings

      const stringOptions = !strings ? null : Object.keys(strings).map((key) => {
        return { key: key, value: strings[key] }
      }).sort((a, b) => {
        return a.value.localeCompare(b.value)
      })

      setState({
        loading: false,
        stringOptions: stringOptions,
        linkOptions: links,
      })
    }).catch(handleError)
  }, [])

  var form = null
  const theItemType = itemType()
  switch(theItemType) {
    case 'showcase':
      form = (
        <DiscoverNewFormHeader title='Showcase'>
          <ShowcaseForm
            state={state}
            setState={setState}
            props={props}
            stringOptions={state.stringOptions}
            linkOptions={state.linkOptions}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    case 'browse_all':
      form = (
        <DiscoverNewFormHeader title='Browse All'>
          <BrowseAllForm
            state={state}
            setState={setState}
            props={props}
            stringOptions={state.stringOptions}
            linkOptions={state.linkOptions}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    case 'effect_image':
      form = (
        <DiscoverNewFormHeader title='Effect Image'>
          <EffectImageForm
            state={state}
            setState={setState}
            props={props}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    case 'effect':
      form = (
        <DiscoverNewFormHeader title='Effect'>
          <EffectForm
            state={state}
            setState={setState}
            props={props}
            stringOptions={state.stringOptions}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    case 'genre':
      form = (
        <DiscoverNewFormHeader title='Genre'>
          <GenreForm
            state={state}
            setState={setState}
            props={props}
            stringOptions={state.stringOptions}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    case 'artist':
      form = (
        <DiscoverNewFormHeader title='Artist'>
          <ArtistForm
            setState={setState}
            props={props}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    case 'producer':
      form = (
        <DiscoverNewFormHeader title='Producer'>
          <ProducerForm
            setState={setState}
            props={props}
            itemType={theItemType}
          />
        </DiscoverNewFormHeader>
      )
      break
    default:
      Sentry.captureMessage(`Unknown discover item type: ${theItemType}`)
  }
  return (
    <div className='body-container'>
      <Layout>
        {form}
      </Layout>
    </div>
  )
}

export default withRouter(AdminNewDiscoverItem)
