import React, { Component } from 'react'
import { CheckboxGroup } from '../../widgets'
import {
  bpms,
  keyScales,
  creatorTypes,
  itemBlockTypes,
  itemTypes,
  reportableTypes,
  regions,
  userBanReporterTypes,
} from '../../shared'

class FilterPicker extends Component {
  render() {
    const {
      name,
      choices,
      defaultValues,
      onChange,
      disabled,
      singleChoice,
    } = this.props

    return (
      <div className='search-filter-picker'>
        <h6>{name}</h6>
        <CheckboxGroup
          defaultValues={defaultValues}
          choices={choices}
          onChange={onChange}
          disabled={disabled}
          className='search-choices'
          singleChoice={singleChoice}
        />
      </div>
    )
  }
}

FilterPicker.Genre = ({onChange, choices, defaultValues}) => (
  <FilterPicker
    name='Genre'
    choices={choices}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={!!defaultValues}
    singleChoice={false}
  />
)

FilterPicker.Bpm = ({onChange, defaultValues}) => (
  <FilterPicker
    name='BPM'
    choices={bpms}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={!!defaultValues}
    singleChoice={false}
  />
)

FilterPicker.Key = ({onChange, defaultValues}) => (
  <FilterPicker
    name='Key'
    choices={keyScales}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={!!defaultValues}
    singleChoice={false}
  />
)

FilterPicker.CreatorType = ({onChange, defaultValues}) => (
  <FilterPicker
    name='CreatorType'
    choices={creatorTypes}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={!!defaultValues}
    singleChoice={false}
  />
)

FilterPicker.ItemBlock = ({onChange, defaultValues, singleChoice}) => (
  <FilterPicker
    name='Block Reason'
    choices={itemBlockTypes}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={false}
    singleChoice={!!singleChoice}
  />
)

FilterPicker.ItemType = ({onChange, defaultValues, singleChoice}) => (
  <FilterPicker
    name='Item Type'
    choices={itemTypes}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={false}
    singleChoice={!!singleChoice}
  />
)

FilterPicker.ReportableType = ({onChange, defaultValues, singleChoice}) => (
  <FilterPicker
    name='Type'
    choices={reportableTypes}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={false}
    singleChoice={!!singleChoice}
  />
)

FilterPicker.ReportReasons = ({onChange, reasons, defaultValues}) => (
  <FilterPicker
    name=''
    choices={reasons}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={false}
    singleChoice={true}
  />
)

FilterPicker.Region = ({onChange}) => (
  <FilterPicker
    name=''
    choices={regions}
    defaultValues={[null]}
    onChange={onChange}
    disabled={false}
    singleChoice={true}
  />
)

FilterPicker.UserBanReporterType = ({onChange, defaultValues}) => (
  <FilterPicker
    name='Type'
    choices={userBanReporterTypes}
    defaultValues={defaultValues}
    onChange={onChange}
    disabled={false}
    singleChoice={true}
  />
)

export default FilterPicker
