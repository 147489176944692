import Button from './Button'
import DatePicker from './DatePicker'

const DateRangePicker = ({minDate, startDate, endDate, onDateChanged, onGo}) => {
  return <div className='flex-row align-center'>
    <DatePicker
      name='Between'
      min={minDate}
      start={startDate}
      onChange={date => onDateChanged('startDate', date)}
    />
    <DatePicker
      name='and'
      min={minDate}
      start={endDate}
      onChange={date => onDateChanged('endDate', date)}
    />
    <Button className='small' onClick={onGo}>
      Go
    </Button>
  </div>
}

export default DateRangePicker
