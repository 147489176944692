import { Layout } from '../widgets'
import { Search } from './components'

const AdminSearch = (props) => (
  <div className='body-container'>
    <Layout>
      <Layout.Header>
        Search
      </Layout.Header>
      <div className='moderator-content'>
        <p className='flex-row'>Search over all content in the system</p>
        <Search tabs={['users', 'beats', 'posts']} />
      </div>
    </Layout>
  </div>
)

export default AdminSearch
