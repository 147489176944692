import { withRouter } from '../shared'
import authClient from './authClient'
import { Button } from '../widgets'

import signoutImg from '../images/signout.svg'

const LogoutButton = (props) => {
  return (
    <a href='#' onClick={() => authClient.logout()}>
      <Button className='app-navigation__menu-button'>
        <img src={signoutImg} alt='' />
        Sign out
      </Button>
    </a>
  )
}

export default withRouter(LogoutButton)
