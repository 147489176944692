import React from 'react'
import * as moment from 'moment'
import { humanizedDuration, formattedDate, formatStat } from '../../shared'
import { BeatCell, PostCell , Cell } from '../../components'

const ProducerEndedBoostCell = ({boost, children}) => {
  const type = boost.item_type === 'BEAT' ? 'Beat' : 'Track'
  return <>
    <Cell noline>
      <Cell.Body>
        <Cell.Line>
          <div>Started on {formattedDate(boost.created_at)} and active for {humanizedDuration(moment(boost.created_at), moment(boost.end_at))}</div>
        </Cell.Line>

        <Cell.Line>
          New engagement
          {boost.use_count > 0 && <Cell.UseCount>{formatStat(boost.use_count)}</Cell.UseCount>}
          {boost.play_count > 0 && <Cell.PlayCount>{formatStat(boost.play_count)}</Cell.PlayCount>}
          {boost.like_count > 0 && <Cell.LikeCount>{formatStat(boost.like_count)}</Cell.LikeCount>}
          {boost.comment_count > 0 && <Cell.CommentCount>{formatStat(boost.comment_count)}</Cell.CommentCount>}
        </Cell.Line>
      </Cell.Body>
    </Cell>
    {boost.item_type === 'BEAT' ? <BeatCell beat={boost.item} smallImage/> : <PostCell post={boost.item} smallImage/>}
    {children}
  </>
}

export default ProducerEndedBoostCell
