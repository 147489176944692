import React, { useState, useContext } from 'react'

import axios from 'axios'
import * as Sentry from '@sentry/react'
import * as routes from '../routes'
import {
  withRouter,
  Loading,
  toPascalCase,
} from '../shared'
import {
  AlertContext,
  Button,
  Layout,
  Input,
  Form,
} from '../widgets'
import {
  FilterPicker,
} from '../admin/components'
import volocoLogo from '../images/voloco_logo.svg'

const toReasonName = (reasonId) => toPascalCase(reasonId.replaceAll('_', ' '))

const BodyLayout = (props) => {
  return <div className='body-container'>
    <Layout className='fullscreen flex-column align-center'>
      {props.children}
    </Layout>
  </div>
}

const BodyText = (props) => {
  return <div className='vfont-body opacity-80'>
    {props.children}
  </div>
}

const RedButton = (props) => {
  return <Button className='medium red min-width-225' onClick={props.onClick}>
    {props.children}
  </Button>
}

const RedButtonOutlined = (props) => {
  return <Button className='medium red-outlined min-width-225' onClick={props.onClick}>
    {props.children}
  </Button>
}

const StartPage = (props) => {
  const {
    nextPage,
  } = props

  return <BodyLayout>
    <img
      className='voloco-logo'
      src={volocoLogo}
      alt='Voloco Logo'
    />
    <BodyText>
      We need you to answer a few questions before we can file this report.
    </BodyText>
    <BodyText>
      Reports are taken seriously, and only should be filed if you believe the content has broken our rules.
    </BodyText>
    <RedButton onClick={nextPage}>
      Start Report
    </RedButton>
  </BodyLayout>
}

const SelectPage = (props) => {
  const {
    nextPage,
    reasons,
    reason,
    setReason,
  } = props

  const reasonsList = reasons.map((x) => { return { id: x.id, value: toReasonName(x.id) }})
  const defaultReason = reasonsList.find((x) => x.id === reason)

  return <BodyLayout>
    <div>
      What is this report about?
    </div>
    <FilterPicker.ReportReasons
      onChange={(values) => setReason(values.length ? values[0] : null)}
      reasons={reasonsList}
      defaultValues={defaultReason == null ? [] : [defaultReason.id]}
    />
    <RedButton onClick={() => {
      if (reason != null) {
        nextPage()
      }
    }}>
     Next
    </RedButton>
  </BodyLayout>
}

const VerifyPage = (props) => {
  const {
    reasonName,
    nextPage,
    prevPage,
  } = props

  return <BodyLayout>
    <div>
      Is this report about {reasonName}?
    </div>
    <RedButton onClick={nextPage}>
     Yes, continue
    </RedButton>
    <RedButtonOutlined onClick={prevPage}>
     No, try again
    </RedButtonOutlined>
  </BodyLayout>
}

const SubmitPage = (props) => {
  const {
    onSubmit,
    textInput,
    setTextInput,
    reasonName,
  } = props

  return <BodyLayout>
    <div>
      Review and submit
    </div>
    <BodyText>
      This report is about {reasonName}.
    </BodyText>
    <div>
      Add more context
    </div>
    <Form.Item>
      <Input.TextArea
        className={'min-width-225'}
        value={textInput}
        onChange={event => setTextInput(event.target.value)}
        rows={6}
      />
    </Form.Item>
    <RedButton onClick={onSubmit}>
     Submit Report
    </RedButton>
  </BodyLayout>
}

const ThankYouPage = (props) => {
  const {
    onDone,
  } = props

  return <BodyLayout>
    <div>
      Thank You
    </div>
    <BodyText>
      Your report has been submitted.
    </BodyText>
    {onDone && <RedButton onClick={onDone}>
      Done
    </RedButton>}
  </BodyLayout>
}

const Report = (props) => {
  const Pages = {
    start: 'start',
    select: 'select',
    verify: 'verify',
    submit: 'submit',
    thankyou: 'thankyou',
  }

  const [loading, setLoading] = useState(false)
  const [reasons, setReasons] = useState(null)
  const [reason, setReason] = useState(null)
  const [textInput, setTextInput] = useState(null)
  const [page, setPage] = useState(Pages.start)

  const { showAlert } = useContext(AlertContext)

  if (loading) {
    return <Loading/>
  }

  if (reasons == null) {
    setLoading(true)
    axios.get(routes.adminModerationReportReasonsUrl)
      .then((response) => {
        setReasons(response.data)
      }).catch((error) => {
        setReasons([])
        Sentry.captureException(error)
      }).finally(() => {
        setLoading(false)
      })
  }

  const itemType = props.router.params.type
  const itemId = props.router.params.id

  const onSubmit = () => {
    if (!textInput || textInput.trim().length < 5) {
      showAlert({ title: 'Add Context', message: 'Write a bit more context to submit this report.'})
      return
    }
    setLoading(true)
    const authToken = window.volocoAuthToken
    axios.post(routes.adminModerationReportUrl, {
      item_type: itemType,
      item_id: itemId,
      reason: reason,
      description: textInput,
    }, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    }).catch((error) => {
      Sentry.captureException(error)
    }).finally(() => {
      setPage(Pages.thankyou)
      setLoading(false)
    })
  }

  var onDone = null
  if (window && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.closeViewController) {
    onDone = () => {
      window.webkit.messageHandlers.closeViewController.postMessage(null)
    }
  } else if (window && window.closeViewController) {
    onDone = () => {
      window.closeViewController.postMessage(null)
    }
  }

  switch (page) {
    case Pages.start:
      return <StartPage
      nextPage={() => setPage(Pages.select)}
    />
    case Pages.select:
      return <SelectPage
      nextPage={() => setPage(Pages.verify)}
      reasons={reasons}
      reason={reason}
      setReason={setReason}
    />
    case Pages.verify:
      return <VerifyPage
      nextPage={() => setPage(Pages.submit)}
      prevPage={() => setPage(Pages.select)}
      reasonName={toReasonName(reason)}
    />
    case Pages.submit:
      return <SubmitPage
      onSubmit={onSubmit}
      textInput={textInput}
      setTextInput={setTextInput}
      reasonName={toReasonName(reason)}
    />
    case Pages.thankyou:
      return <ThankYouPage
      onDone={onDone}
    />
    default:
      return null
  }
}

export default withRouter(Report)
