import classNames from './util'

const Input = (_props) => {
  const props = {..._props}
  if (props.type !== 'file' && props.hasOwnProperty('value') && props.value === null) {
    props.value = ''
  }

  if (props.before) {
    return <div className='widgets-Input'>
      <span className='widgets-Input-before'>{props.before}</span>
      <input {...props} className={classNames('widgets-Input-input', props.readOnly ? 'readOnly' : '', props.className)}>{props.children}</input>
    </div>
  } else {
    return <input {...props} className={classNames('widgets-Input', props.readOnly ? 'readOnly' : '', props.className)}>{props.children}</input>
  }
}

Input.TextArea = (props) => <textarea
  {...props}
  value={props.value || ''}
  className={classNames('widgets-Input', props.className)}
>
  {props.children}
</textarea>

export default Input
