import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Layout, AlertContext } from '../widgets'
import { AudioPlayerContext } from '../components/AudioPlayerContext'
import * as routes from '../routes'
import {
  PlaylistCell,
  PostCell,
  PaginatedList,
} from '../components'
import {
  Loading,
  withRouter,
} from '../shared'

const tabs = [
  'Posts',
  'Edit',
]

const EditPlaylist = (props) => {
  const [loading, setLoading] = useState(true)

  const [playlist, setPlaylist] = useState(null)
  const [name, setName] = useState(null)

  useEffect(() => {
    load()
  }, [])

  const { showAlert, showAlertConfirm, handleError } = useContext(AlertContext)
  const { loadAndPlayPost, pauseItem, isItemPlaying } = useContext(AudioPlayerContext)

  const playlistId = () => props.router.params.playlistId

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.playlistUrl, playlistId())
    ).then((response) => {
      const playlist = response.data
      setPlaylist(playlist)
      setName(playlist.name)
    }).catch(
      handleError
    ).finally(() => {
      setLoading(false)
    })
  }

  const onTogglePlayPause = (post) => {
    if (!post) { return }
    if (isItemPlaying(post.id)) {
      pauseItem(post.id)
    } else {
      loadAndPlayPost(post)
    }
  }

  if (loading) {
    return (<Loading />)
  }

  return (
    <div className='body-container'>
      <Layout>
        <Layout.Header>
          {playlist.name}
        </Layout.Header>

        <PlaylistCell playlist={playlist} hideName={true} />

        <PaginatedList
          key={'tracks'}
          loadUrl={routes.dynamic(routes.playlistItemUrl, playlistId())}
          cell={PostCell}
          cellItemPropName={'post'}
          cellAdditionalProps={{
            onPlayPause: (post) => onTogglePlayPause(post),
            isPlaying: (post) => isItemPlaying(post.id),
          }}
        />

      </Layout>
    </div>
  )
}

export default withRouter(EditPlaylist)
