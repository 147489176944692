import { useContext } from 'react'
import { Menu, AlertContext } from '../widgets'
import { withRouter } from '../shared'
import 'firebase/compat/auth'
import authClient from './authClient'
import FirebaseAuthLogin from './FirebaseAuthLogin'

import volocoLogo from '../images/voloco_logo.svg'

const LoginButton = (props) => {

  const { showAlertError, handleError } = useContext(AlertContext)

  const onSuccess = (result) => {
    const afterLoginPath = (props.router.location.state || {}).intendedLocation || props.afterLoginPath
    authClient.loginToRezcav(
      result.user, props.signInUrl
    ).then(() => {
      props.router.navigate(afterLoginPath)
    }).catch((error) => {
      if (error.response && error.response.status === 403) {
        props.router.navigate(props.signUpPath)
      } else {
        handleError(error)
      }
    })
  }

  const onError = (error) => {
    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signInWithPopup
    const errorCode = error.code
    if (['auth/popup-closed-by-user', 'auth/cancelled-popup-request'].includes(errorCode)) {
      // ignored error codes
    } else {
      // use setTimeout to allow the popup to close, then tidy the error message for the user
      setTimeout(() => showAlertError(error.message.replace('Firebase: ', '').replace(/\(.*\)\./, '')))
    }
  }

  return (
    <div className='welcome'>
      <Menu className='welcome-header'>
        <Menu.Item key='logo' disabled>
          <a href='https://resonantcavity.com'>
            <img
              className='voloco-logo'
              src={volocoLogo}
              alt='Voloco Logo'
            />
          </a>
        </Menu.Item>
      </Menu>
      <div className='welcome-content'>
        {authClient.isGoogleAvailable ? <>
          <div className='welcome-title'>Continue with</div>
          {<FirebaseAuthLogin providers={props.providers} onSuccess={onSuccess} onError={onError} />}
        </> : <>
          <div className='welcome-title'>Unavailable</div>
          <div className='welcome-body'>
            <ul className='welcome-body-content'>
              <div>Voloco may not available in your region. Please refresh the page to try again.</div>
            </ul>
          </div>
        </>}
      </div>
      <div className='welcome-footer'></div>
    </div>
  )
}

export default withRouter(LoginButton)
