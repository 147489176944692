import { useContext, useState} from 'react'
import axios from 'axios'
import Lottie from 'lottie-react'
import * as routes from '../routes'
import { Button, Layout, AlertContext } from '../widgets'
import globals from '../globals'
import { withRouter, Loading } from '../shared'
import authClient from '../auth/authClient'
import boostImg from '../images/boost.svg'
import boostCommentsLottie from '../lotties/boost-comments.json'
import boostLikesLottie from '../lotties/boost-likes.json'
import boostPlaysLottie from '../lotties/boost-plays.json'

const PurchaseBeatBoost = (props) => {
  const beatId = props.router.params.id
  const boostBeatPrices = globals.stripePrices.humanized_prices
  const boostBeatSku = authClient.remoteConfigValue('boost_beat_sku_web') || 'boost_beat_a'
  const boostBeatPrice = boostBeatPrices[boostBeatSku]

  const [loading, setLoading] = useState(false)
  const [beat, setBeat] = useState(null)

  const { handleError } = useContext(AlertContext)

  const onCheckout = () => {
    if (!beat) {
      return
    }
    axios.post(routes.stripeCheckoutSessionUrl, {
      product_id: boostBeatSku,
      item_type: 'BEAT',
      item_id: beat.id,
    }).then((response) => {
      window.location.replace(response.data.url)
    }).catch(handleError)
  }

  if (loading) {
    return <Loading />
  }

  if (beat === undefined) {
    props.router.navigate(routes.producerBeatsPath)
  }

  if (beat === null) {
    if (!beatId) {
      setLoading(true)
      axios.get(routes.beatsPublicUserUrl, {
        params: {
          requested_user_id: authClient.userId(),
          page_size: 1,
        }
      }).then((response) => {
        if (response.data.beats) {
          setBeat(response.data.beats[0])
        } else {
          setBeat(undefined)
        }
      }).catch((error) => {
        setBeat(undefined)
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setLoading(true)
      axios.get(
        routes.dynamic(routes.getBeatUrl, beatId)
      ).then((response) => {
        setBeat(response.data)
      }).catch((error) => {
        setBeat(undefined)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  return (
    <Layout className='purchase-beat-boost'>
      <Layout.Header>
        {beat &&
          <div className='purchase-beat-boost__image-container'>
            <img className='purchase-beat-boost__image' src={beat.images['400']} alt='' />
            <div className='purchase-beat-boost__image-content'>
              <div className='beatname'>{beat.beat_name}</div>
              <div className='username'>{beat.producer_name}</div>
              <img className='user-image' src={beat.user_profile_images['100']} alt='' />
              <div className='boost-numbers'>
                <Lottie className='lottie' animationData={boostCommentsLottie} loop={false} />
                <Lottie className='lottie' animationData={boostLikesLottie} loop={false} />
                <Lottie className='lottie' animationData={boostPlaysLottie} loop={false} />
              </div>
            </div>
          </div>
        }
      </Layout.Header>
      <div className='layout-content'>
        <div className='purchase-beat-boost__title'>
          Boost Your Beat!
        </div>
        <div className='purchase-beat-boost__description color-text-light font-12'>
          <div>• Get your beat heard by thousands of artists</div>
          <div>• Grow your following and get feedback on your music!</div>
          <div>• See your Boost stats once it's completed</div>
        </div>
        <br/>
        <div>
          <div className='flex-row align-center space-between left-text red-outline'>
            <div>
              <div className='color-text font-14'>One Time Payment</div>
              <div className='color-text-light font-12'>60 Minute Boost</div>
            </div>
            <div className='color-text font-12'>{boostBeatPrice}</div>
          </div>
          <br/>
          <Button className='flexrow red' onClick={onCheckout}>
            <img src={boostImg} alt='' />
            Boost Now
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default withRouter(PurchaseBeatBoost)
