import { useState, useContext } from 'react'
import { Input, AlertContext } from '../widgets'
import FilePickerOverlay from './FilePickerOverlay'
import {
  allowedImageTypes,
  validateImageFile,
} from '../shared'

const ImageFilePicker = (props) => {

  const [rawImageData, setRawImageData] = useState(null)
  const { showAlertError } = useContext(AlertContext)

  const handleFileChanged = (event) => {
    const file = event.target.files[0]
    if (file) {
      validateImageFile(file, (error) => {
        if (error) {
          showAlertError(error)
        } else {
          const reader = new FileReader()
          reader.onloadend = () => {
            setRawImageData(reader.result)
          }
          reader.readAsDataURL(file)
        }
      })
    } else {
      setRawImageData(null)
    }
    props.onChange(file)
  }

  return (
    <FilePickerOverlay
      inputName='image-file'
      title='Cover Image *'
      description='<p>Must be: .jpeg, .jpg, or .png</p><p>Must be less than 15MB</p>'
      image={rawImageData}
    >
      <Input
        required
        name='image-file'
        id='image-file'
        type='file'
        className='producer-file-upload-field'
        accept={allowedImageTypes}
        onChange={handleFileChanged}
      />
    </FilePickerOverlay>
  )
}

export default ImageFilePicker
