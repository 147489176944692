import { useContext, useRef } from 'react'
import {
  getAuth,
  signInWithPopup,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
} from 'firebase/auth'
import { Button, Input, AlertContext, Form } from '../widgets'

const FirebaseAuthLogin = (props) => {

  const { showAlert, showAlertError } = useContext(AlertContext)

  const phoneNumberRef = useRef(null)
  const verificationCodeRef = useRef(null)

  const signIn = (provider) => {
    signInWithPopup(getAuth(), provider).then(props.onSuccess).catch(props.onError)
  }

  const onGoogleClicked = () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account'
    })
    signIn(provider)
  }

  const onFacebookClicked = () => {
    signIn(new FacebookAuthProvider())
  }

  const onAppleClicked = () => {
    signIn(new OAuthProvider('apple.com'))
  }

  const onPhoneClicked = () => {
    phoneNumberRef.current = null

    showAlert({
      title: 'Continue with Phone',
      message: <>
        Please enter your phone number, including country and area code.<br/><br/>
        Standard message and data rates may apply, depending on your mobile carrier and plan.<br/><br/>
        If you have any questions, contact your mobile carrier for more information.<br/><br/>
        <Form.Item>
          <Input
            type='text'
            onChange={(event) => phoneNumberRef.current = event.target.value}
          />
        </Form.Item>
      </>,
      callback: () => {
        onPhoneNumberEntered(phoneNumberRef.current)
      }
    })
  }

  const onPhoneNumberEntered = (phoneNumber) => {
    verificationCodeRef.current = null

    if (!phoneNumber) { return }
    phoneNumber = phoneNumber.replace(/\D/g,'')
    if (!phoneNumber) { return }
    if (phoneNumber.length === 10) {
      phoneNumber = '1' + phoneNumber // assume United States country code
    }
    phoneNumber = '+' + phoneNumber

    // reset the recaptcha container
    document.getElementById('recaptcha').innerHTML = ''
    const recaptcha = new RecaptchaVerifier(getAuth(), 'recaptcha', {})

    signInWithPhoneNumber(getAuth(), phoneNumber, recaptcha).then((confirmationResult) => {
      showAlert({
        title: 'Enter Verification Code',
        message: <>
          {`Please enter the verification code sent to ${phoneNumber}`}<br/><br/>
          <Form.Item>
            <Input
              type='text'
              onChange={(event) => verificationCodeRef.current = event.target.value}
            />
          </Form.Item>
        </>,
        callback: () => {
          const code = verificationCodeRef.current
          if (!code) { return }
          confirmationResult.confirm(code).then(props.onSuccess).catch(props.onError)
        }
      })
    }).catch((error) => {
      if (error.code === 'auth/invalid-phone-number') {
        props.onError({ message: `Invalid phone number ${phoneNumber}. Please try again.`})
      } else {
        props.onError(error)
      }
    })
  }

  return <div className='firebase-auth-login'>
    {props.providers.includes('google') && <Button onClick={onGoogleClicked} className='google'>
      <img src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg' />
      Google
    </Button>}
    {props.providers.includes('facebook') && <Button onClick={onFacebookClicked} className='facebook'>
      <img src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg' />
      Facebook
    </Button>}
    {props.providers.includes('apple') && <Button onClick={onAppleClicked} className='apple'>
      <img src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/apple.png' />
      Apple
    </Button>}
    {props.providers.includes('phone') && <>
      <Button onClick={onPhoneClicked} className='phone'>
        <img src='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/phone.svg' />
        Phone
      </Button>
      <div id='recaptcha'></div>
    </>}
  </div>
}

export default FirebaseAuthLogin
