import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Loading, withRouter } from '../shared'
import { AlertContext, Layout } from '../widgets'
import { PaginatedList } from '../components'
import * as routes from '../routes'
import AdminMessageCell from './components/AdminMessageCell'

const AdminConversation = (props) => {
  const { handleError } = useContext(AlertContext)

  const [loading, setLoading] = useState(true)
  const [conversation, setConversation] = useState(null)

  const conversationId = () => {
    return props.router.params.id
  }

  const load = () => {
    setLoading(true)
    axios.get(
      routes.dynamic(routes.adminGetConversationUrl, conversationId())
    ).then((response) => {
      setConversation(response.data)
    }).catch((error) => {
      handleError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(load, [])

  return loading ? <Loading /> : <div className='body-container'>
    <Layout>
      <Layout.Header>{conversationId()}</Layout.Header>

      {conversation && <PaginatedList
        loadUrl={routes.dynamic(routes.adminConversationListMessagesUrl, conversationId())}
        loadParams={{}}
        cell={AdminMessageCell}
        cellItemPropName={'message'}
        cellAdditionalProps={{ members: conversation.members }}
      />}
    </Layout>
  </div>
}

export default withRouter(AdminConversation)
