import React from 'react'
import { Layout } from '../widgets'

const SubmissionTips = (props) => {

  const Header = (props) => (
    <Layout.Header>{props.children}</Layout.Header>
  )

  const Heading = (props) => (
    <h5>{props.children}</h5>
  )

  const Body = (props) => (
    <div className='submission-tips-body'>{props.children}</div>
  )

  return (
    <div className='body-container submission-tips'>
      <Layout>
        <Header>
          Beat Upload Tips
        </Header>
        <div className='layout-content'>
          <div className='video-embed-container'>
            <iframe src='https://www.youtube.com/embed/k1jE5QqBojY' title='video' frameBorder='0' allowFullScreen />
          </div>
          <Heading>
            What is Voloco listening for in your beats?
          </Heading>
          <Body>
            It is important to understand that the beats that we feature
            on Voloco are there for people to make music on top of. With
            that in mind, here are some things that we look for in our
            beats.
          </Body>

          <ul>
            <li>
              <Heading>
                Good Audio Quality
              </Heading>
              <ul>
                <li>
                  <Body>
                    Beats with a solid Mix and Master will have a much better
                    chance at being featured than beats with elements that are
                    out of control. For example, a beat with an 808 bass or
                    kick that is hitting too loudly will not get featured.
                    Please reference other featured beats, or beats from top
                    Hip Hop artists to compare to yours. This might help you
                    determine if your beat is well balanced and loud enough.
                  </Body>
                </li>
              </ul>
            </li>

            <li>
              <Heading>
                Musicality
              </Heading>
              <ul>
                <li>
                  <Body>
                    We have provided space for you to enter the BPM and Key of the
                    beat you are submitting. If you do not enter these values, Voloco
                    will attempt to detect them. To ensure accuracy, we recommend
                    that you add these values yourself. You may also go back to
                    previously submitted beats and add or change these values.
                  </Body>
                </li>
                <li>
                  <Body>
                    Beats that are musically inviting have the best chance at being featured.
                    If the chord progression is too complex, it may be hard for some users to
                    effectively use the beat. Keep it simple so the vocalist can have as much
                    room as possible to explore.
                  </Body>
                </li>
                <li>
                  <Body>
                    Beats that have instrumental space for a vocalist are also desired.
                    For example, beats with a very active lead playing a loud melody might
                    get in the way of a vocalist and make it harder for them to effectively
                    use the beat. Leave room for the vocalist.
                  </Body>
                </li>
                <li>
                  <Body>
                    Beats that have vocals in them, or beats that include a vocal hook/chorus,
                    have a small chance at being featured because the vocal is the one element
                    that the users get to add. No hooks please.
                  </Body>
                </li>
              </ul>
            </li>

            <li>
              <Heading>
                Style/Genre
              </Heading>
              <ul>
                <li>
                  <Body>
                    Voloco’s user base stretches across the world. We have users with diverse
                    backgrounds and in turn, diverse music tastes. We support all genres of music
                    and urge our producers to upload diverse styles.
                  </Body>
                </li>
                <li>
                  <Body>
                    If you would like to know which types of beats get used and featured the most,
                    please visit our beat page on the app and look at the numbers for each one.
                    These numbers reflect the number of times users used the beat, not the number
                    of plays.
                  </Body>
                </li>
              </ul>
            </li>

            <li>
              <Heading>
                Artwork
              </Heading>
              <ul>
                <li>
                  <Body>
                    Voloco is an app for all ages so please upload artwork that is suitable for all
                    eyes. Artwork that depicts violence, drug use, nudity or other content not suitable
                    for a general audience will be rejected. Voloco has a zero tolerance policy for any
                    hate symbols, speech, and/or references on our platform.
                  </Body>
                </li>
              </ul>
            </li>
          </ul>

          <Heading>
            Additional Submission Tips
          </Heading>
          <ul>
            <li>
              Upload no more than two beats per day.
            </li>
            <li>
              Use only one account. Multiple accounts will not increase feature rate.
            </li>
            <li>
              Use a unique producer tag as a watermark in your beats so we know it’s yours.
            </li>
            <li>
              Name your files effectively.
            </li>
            <li>
              Upload consistently to build familiarity with your sound and style.
            </li>
          </ul>

          <Heading>
            Regarding beat fraud and copyright infringement
          </Heading>
          <Body>
            As you may know, there are a lot of people out there who take free beats and sell them as
            their own. This is damaging to the music making community, and Voloco does not tolerate
            copyright infringement in any form. Everything that you upload must be your own work.
            Even if you download the beat for free, if you don’t have the copyright, we cannot accept
            it.
          </Body>

          <Body>
            Questions or feedback about your beats, or our featuring process? Message us at
            <a href={'mailto:volocobeats@resonantcavity.com'}> volocobeats@resonantcavity.com </a>
            and our team will get back to you.
          </Body>
        </div>
      </Layout>
    </div>
  )
}

export default SubmissionTips
