import React from 'react'
import * as moment from 'moment'
import { Button } from '../../widgets'

const UserBanInput = ({onChange, onSave, isBanned}) => {
  var actions = null
  if (isBanned) {
    actions = <>
      <Button
        className='user-ban-input--button unban'
        onClick={onSave}
      >
        <span>Banned</span>
      </Button>
    </>
  } else {
    actions = <>
      <Button
        className='user-ban-input--button'
        onClick={onSave}
      >
        <span>Ban</span>
      </Button>
      <input
        type='date'
        min={moment().add(1,'days').startOf('day').format('YYYY-MM-DD')}
        onChange={(event) => onChange(event.target.value)}
      />
    </>
  }

  return (
    <div className='user-ban-input'>
      {actions}
    </div>
  )
}

export default UserBanInput
