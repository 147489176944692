import React from 'react'
import CookieConsent from 'react-cookie-consent'
import * as routes from '../routes'

const excludedBasePaths = [
  routes.producerReportBasePath,
]

const CookieConsentBar = () => !excludedBasePaths.some(x => window.location.pathname.startsWith(x)) && (
  <CookieConsent
    disableStyles={true}
    containerClasses='cookie-consent'
    contentClasses='cookie-consent__content'
    buttonClasses='cookie-consent__button'
    buttonText='I Agree'
    buttonStyle={{
      background: 'white',
      borderRadius: '4px',
      color: 'black'
    }}
  >
    This website uses <a href='https://resonantcavity.com/cookie-policy/'>cookies</a> for your best experience.
  </CookieConsent>
)

export default CookieConsentBar
