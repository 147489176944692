import classNames from './util'

const Tooltip = (props) => <div
  className={classNames('widgets-Tooltip', props.className)}
>
  <div className='widgets-Tooltip-Anchor'>{props.children}</div>
  <div className='widgets-Tooltip-Content'>{props.title}</div>
</div>

export default Tooltip
