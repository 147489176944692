import { niceIso8601Time } from '../../shared'
import { Cell } from '../../components'
import UserLink from './UserLink'

const AdminMessageCell = ({members, message}) => <div className='margin-vertical-8'>
  <Cell.Body>
    <Cell.Line>
      <UserLink
        user_id={message.user_id}
        username={members?.find((user) => user.user_id === message.user_id)?.username || `${message.user_id} <deleted>`}
      />
      <Cell.LineNotice>
        {niceIso8601Time(message.created_at)}
      </Cell.LineNotice>
    </Cell.Line>
    <div>{message.data}</div>
  </Cell.Body>
</div>

export default AdminMessageCell
