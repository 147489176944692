import classNames from './util'

const Form = (props) => <form
  onSubmit={props.onSubmit}
  className={classNames('widgets-Form', props.className)}
>
  {props.children}
</form>

Form.Item = (props) => <div
  className={classNames('widgets-Form-Item', props.className)}
>
  <label>
    {props.label}
  </label>
  {props.children}
</div>

Form.Button = (props) => <button
  type='submit'
  disabled={props.loading}
  className={classNames('widgets-Button', 'widgets-Form-Button', props.className)}
>
  {props.loading ? <div className='spinner' /> : props.children}
</button>

export default Form
