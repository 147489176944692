import React from 'react'
import {
  Navigate
} from 'react-router-dom'
import authClient from './authClient'
import { Loading, withRouter } from '../shared'

// Helpful article
// https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
class PrivateRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    authClient.initialize(() => {
      const handler = () => { this.setState({ loading: false }) }
      authClient.tryLogin(this.props.loginPath).then(handler).catch(handler)
    })
  }

  render() {
    const { element, unauthedPath, ...rest } = this.props
    if (this.state.loading) {
      return (<Loading />)
    } else {
      if (authClient.isLoggedIn()) {
        return element
      } else {
        return (
          <Navigate
            to={unauthedPath}
            options={{
              state: { intendedLocation: this.props.router.location }
            }}
          />
        )
      }
    }
  }
}

export default withRouter(PrivateRoute)
