import { useState, useContext } from 'react'
import { Input, AlertContext } from '../widgets'
import FilePickerOverlay from './FilePickerOverlay'
import {
  allowedAudioTypes,
  validateAudioFile,
} from '../shared'

const AudioFilePicker = (props) => {

  const [filename, setFilename] = useState(null)
  const { showAlertError }  = useContext(AlertContext)

  const handleFileChanged = (event) => {
    const file = event.target.files[0]
    if (file) {
      validateAudioFile(file, (error) => {
        if (error) {
          showAlertError(error)
        } else {
          setFilename(file.name)
        }
      })
    } else {
      setFilename(null)
    }
    props.onChange(file)
  }

  return (
    <FilePickerOverlay
      inputName='audio-file'
      title='Audio File *'
      description='<p>Must be: .wav, .aif, .aac, .m4a, .mp3, or .ogg</p><p>Must be less than 75MB</p>'
      audioFile={filename}
    >
      <Input
        required
        name='audio-file'
        id='audio-file'
        type='file'
        className='producer-file-upload-field'
        accept={allowedAudioTypes}
        onChange={handleFileChanged}
      />
    </FilePickerOverlay>
  )
}

export default AudioFilePicker
