import { createContext, useState, useEffect } from 'react'
import ReactPlayer from 'react-player/file'

import PublicItemLink from './PublicItemLink'
import PublicUserLink from './PublicUserLink'
import volumeNone from '../images/volume-none.svg'
import volumeLow from '../images/volume-low.svg'
import volumeHigh from '../images/volume-high.svg'
import pauseImg from '../images/player-pause.svg'
import playImg from '../images/player-play.svg'
import skipPreviousImg from '../images/player-skip-previous.svg'
import skipNextImg from '../images/player-skip-next.svg'

export const AudioPlayerContext = createContext('audio-player-context')

export const AudioPlayerContextProvider = ({withNavigationSidebar, children}) => {
  const [itemList, setItemList] = useState([])
  const [currentAudio, setCurrentAudio] = useState({})
  const [player, setPlayer] = useState(null)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [seeking, setSeeking] = useState(false)
  const [volume, setVolume] = useState(0.8)
  const [mutedVolume, setMutedVolume] = useState(null)

  useEffect(() => {
    ensureItemLoaded()
  }, [itemList])

  const mapPost = (post) => ({
    name: post.title,
    userId: post.user_id,
    username: post.user_name,
    itemType: 'POST',
    itemId: post.id,
    fileSrc: post.signed_track_url,
    streamSrc: post.signed_track_stream_url,
    images: post.signed_image_urls,
  })

  const mapBeat = (beat) => ({
    name: beat.beat_name,
    userId: beat.producer_user_id,
    username: beat.producer_name,
    itemType: 'BEAT',
    itemId: beat.id,
    fileSrc: beat.audio_file_url,
    streamSrc: beat.audio_stream_url,
    images: beat.images,
  })

  const setItemsFromPosts = (posts) => setItemList(posts.map(mapPost))

  const setItemsFromBeats = (beats) => setItemList(beats.map(mapBeat))

  const loadAndPlayPost = (post) => {
    setItemList([])
    setCurrentAudio({...mapPost(post), playing: true})
  }

  const loadAndPlayBeat = (beat) => {
    setItemList([])
    setCurrentAudio({...mapBeat(beat), playing: true})
  }

  const ensureItemLoaded = () => {
    if (Object.keys(currentAudio).length > 0 || itemList.length <= 0) { return }
    setCurrentAudio({...itemList[0], playing: false})
  }

  const findItem = (itemId) => itemList.find((item) => item.itemId === itemId)

  const currentAudioIndex = () => {
    for (const [index, item] of itemList.entries()) {
      if (item.itemId === currentAudio.itemId) {
        return index
      }
    }
    return 0
  }

  const playItem = (itemId) => {
    const item = findItem(itemId)
    setCurrentAudio({...item, playing: true})
  }

  const pauseItem = (itemId) => {
    if (isItemPlaying(itemId)) {
      onPause()
    }
  }

  const isItemInListLoaded = () => itemList.some((item) => item.itemId === currentAudio.itemId)

  const isItemInListPlaying = () => isItemInListLoaded() && isPlaying()

  const ensureItemInListIsPlaying = () => {
    if (itemList.length <= 0) {
      return
    }
    setCurrentAudio({...itemList[0], playing: true})
  }

  const isItemPlaying = (itemId) => currentAudio.itemId === itemId && currentAudio.playing

  const onPlayPause = () => {
    setCurrentAudio({...currentAudio, playing: !currentAudio.playing})
  }

  const isPlaying = () => currentAudio.playing

  const onSkipPrevious = () => {
    const currentAudioIdx = currentAudioIndex()
    if ((progress * duration) < 3 && itemList.length > 1 && currentAudioIdx > 0) {
      const prevItem = itemList[currentAudioIdx - 1]
      setCurrentAudio({...prevItem, playing: currentAudio.playing})
    } else {
      player.seekTo(0)
    }
  }

  const onSkipNext = (playing) => {
    const nextItem = itemList[currentAudioIndex() + 1]
    setCurrentAudio({...nextItem, playing: playing})
  }

  const onPause = () => setCurrentAudio({...currentAudio, playing: false})

  const onPlay = () => setCurrentAudio({...currentAudio, playing: true})

  const onSeek = (value) => setProgress(value / duration)

  const onEnded = () => {
    if (isSkipNextEnabled()) {
      onSkipNext(true)
    } else {
      onPause()
      onSeek(0)
    }
  }

  const onProgress = (state) => {
    if (!seeking && currentAudio.playing) {
      // only update 'played', ignore 'playedSeconds', 'loadedSeconds', and 'loaded'
      setProgress(state.played)
    }
  }

  const onDuration = (duration) => setDuration(duration)

  const onSeekMouseDown = (e) => setSeeking(true)

  const onSeekChange = (e) => setProgress(parseFloat(e.target.value))

  const onSeekMouseUp = (e) => {
    setSeeking(false)
    player.seekTo(parseFloat(e.target.value))
  }

  const onVolumeChanged = (e) => {
    setVolume(parseFloat(e.target.value))
    setMutedVolume(null)
  }

  const onVolumeClicked = () => {
    if (mutedVolume) {
      setVolume(mutedVolume)
      setMutedVolume(null)
    } else {
      setMutedVolume(volume)
      setVolume(0)
    }
  }

  const volumeImage = () => {
    if (volume < 0.001) { return volumeNone }
    if (volume < 0.5) { return volumeLow }
    return volumeHigh
  }

  const isSkipNextEnabled = () => {
    if (itemList.length <= 1) {
      return false
    }
    if (currentAudio?.itemId === itemList[itemList.length-1].itemId) {
      return false
    }
    return true
  }

  const targetSrc = currentAudio.streamSrc || currentAudio.fileSrc;

  return <AudioPlayerContext.Provider
    value={{
      setItemsFromPosts,
      setItemsFromBeats,
      loadAndPlayPost,
      loadAndPlayBeat,
      isItemPlaying,
      playItem,
      pauseItem,
      isItemInListLoaded,
      isItemInListPlaying,
      onPlayPause,
      ensureItemInListIsPlaying,
    }}>

    <div className='margin-bottom-120'>
      {children}
    </div>

    {targetSrc &&
      <div className={'audio-player-bar' + (withNavigationSidebar ? ' with-navigation-sidebar' : '')}>
        <ReactPlayer
          ref={setPlayer}
          width='0'
          height='0'
          volume={volume}
          url={targetSrc}
          playing={currentAudio.playing}
          progressInterval={50}
          onPause={onPause}
          onPlay={onPlay}
          onSeek={onSeek}
          onEnded={onEnded}
          onProgress={onProgress}
          onDuration={onDuration}
        />

        <div className='seek-controls no-margin'>
          <AudioPlayerContext.SeekBar
            className='seek-bar'
            duration={duration}
            progress={progress}
            handleMouseDown={onSeekMouseDown}
            handleChange={onSeekChange}
            handleMouseUp={onSeekMouseUp}
          />
        </div>

        <div className='audio-player-bar-body'>

          <div className='audio-player-info'>
            <img className='audio-player-info-img' src={currentAudio.images && currentAudio.images['400']} alt='' />
            <div className='flex-column align-start gap-0'>
              <PublicItemLink name={currentAudio.name} type={currentAudio.itemType} id={currentAudio.itemId} />
              <PublicUserLink small user_id={currentAudio.userId} username={currentAudio.username} />
            </div>
          </div>

          <div className='playback-controls-container'>
            <div className='playback-controls'>

              <div className='playback-controls-left'>
                <div className='player-button skip-previous' onClick={onSkipPrevious}>
                  <img src={skipPreviousImg} alt='skip previous' draggable='false'/>
                </div>
              </div>

              <div className={'player-button play-pause'} onClick={onPlayPause}>
                <img src={currentAudio.playing ? pauseImg : playImg} alt='play' draggable='false'/>
              </div>

              <div className='playback-controls-right'>
                <div className={`player-button skip-next ${isSkipNextEnabled() ? '' : 'disabled'}`} onClick={() => onSkipNext(isPlaying())}>
                  <img src={skipNextImg} alt='skip next' draggable='false'/>
                </div>
              </div>

            </div>
          </div>

          <div className='audio-player-volume'>
            <div className='audio-player-volume-icon' onClick={onVolumeClicked}>
              <img src={volumeImage()} draggable='false'/>
            </div>

            <div className='max-width-200 margin-right-16'>
              <AudioPlayerContext.SeekBar
                className='seek-bar flex-column align-center'
                progress={volume}
                handleMouseDown={onVolumeChanged}
                handleChange={onVolumeChanged}
                handleMouseUp={onVolumeChanged}
              />
            </div>
          </div>

        </div>

      </div>
    }
  </AudioPlayerContext.Provider>
}

AudioPlayerContext.SeekBar = ({ className, progress, handleMouseDown, handleChange, handleMouseUp }) => (
  <input
    className={className}
    type='range'
    min={0}
    max={0.9999}
    step='any'
    value={progress}
    onChange={handleChange}
    // desktop support
    onMouseDown={handleMouseDown}
    onMouseUp={handleMouseUp}
    // mobile support
    onTouchStart={handleMouseDown}
    onTouchEnd={handleMouseUp}
  />
)

AudioPlayerContext.Stat = ({image, value}) => <div className='flex-column align-center gap-8'>
  <div className='public-view-stat value'>{value}</div>
  <img className='public-view-stat image' src={image} alt='' draggable='false'/>
</div>
