import axios from 'axios'
import * as routes from './routes'

class Globals {
  constructor() {
    this.storeModel = null
    this.genres = null
    this.stripePrices = null
  }

  load(callback) {
    const storeRequest = axios.get(routes.storePublicUrl)
    const genreRequest = axios.get(routes.genreUrl)
    const stripePricesRequest = axios.get(routes.stripePricesUrl)
    axios.all(
      [storeRequest, genreRequest, stripePricesRequest]
    ).then(axios.spread((storeResponse, genreResponse, stripeResponse) => {
      this.storeModel = storeResponse.data
      this.genres = genreResponse.data.data
      this.stripePrices = stripeResponse.data
      callback()
    })).catch((error) => {
      callback(error)
    })
  }
}

export default new Globals()
