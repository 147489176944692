import React, { useContext, useEffect, useState } from 'react'
import * as moment from 'moment'
import axios from 'axios'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Area,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts'
import * as routes from '../routes'
import { Layout, DateRangePicker, AlertContext } from '../widgets'
import {
  Loading
} from '../shared'

const AdminAnalytics = (props) => {
  const [state, replaceState] = useState({
    loading: true,
    startDate: moment().utc().subtract(31, 'days'),
    endDate: moment().utc().subtract(1, 'days'),
    charts: [],
  })

  const setState = (subState) => {
    replaceState(state => {
      return {...state, ...subState}
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const { handleError } = useContext(AlertContext)

  const toLocalChartData = (serverData) => {
    // convert the server data to local chart data
    const localData = []
    for (const chartData of serverData) {
      const dataPointsByDay = chartData.dataPoints.reduce((out, dataPoint) => {
        var day = dataPoint.day
        out[day] = out[day] || []
        out[day].push(dataPoint)
        return out
      }, {})
      const dataPointNames = [...new Set(chartData.dataPoints.map(dataPoint => dataPoint.name))]
      const dataPoints = []

      for (var date = state.startDate.clone(); date.diff(state.endDate.endOf('day')) <= 0; date.add(1, 'days')) {
        const day = date.format('M/D/YY')
        const dataPointsOnDay = dataPointsByDay[day] || []
        for (const dataPointName of dataPointNames) {
          if (!dataPointsOnDay.find((dataPoint) => dataPoint.name === dataPointName)) {
            // fill in missing days with zeros
            dataPointsOnDay.push({name: dataPointName, day: day, count: 0})
          }
        }

        // format the data for recharts
        dataPoints.push(dataPointsOnDay.reduce((out, dataPoint) => {
          out[dataPoint.name] = dataPoint.count
          return out
        }, {day: day}))
      }

      localData.push({
        name: chartData.name,
        type: chartData.type,
        dataPoints: dataPoints,
        dataPointNames: dataPointNames,
      })
    }

    // done
    return localData
  }

  const loadData = () => {
    axios.get(routes.adminAnalyticsUrl, {
      params: {
        start: state.startDate.format('YYYY-MM-DD'),
        end: state.endDate.format('YYYY-MM-DD'),
      }
    })
      .then((response) => {
        const data = response.data
        const charts = toLocalChartData(data)
        setState({
          charts: charts
        })
      }).catch((error) => {
        handleError(error)
      }).finally(() => {
        setState({ loading: false })
      })
  }

  const onDateChanged = (stateKey, date) => {
    setState({
      items: [],
      offset: 0,
      [stateKey]: moment(date, 'YYYY-MM-DD').utc(),
    })
  }

  if (state.loading) {
    return (<Loading />)
  }

  const body = (
    <Layout>
      <Layout.Header>Analytics</Layout.Header>
      <DateRangePicker
        minDate={'2018-10-06'}
        startDate={state.startDate}
        endDate={state.endDate}
        onDateChanged={onDateChanged}
        onGo={loadData}
      />
      {state.charts.map((chart, index) => {
        const ChartComponent = chart.type === 'stacked' ? AreaChart : LineChart
        const SeriesComponent = chart.type === 'stacked' ? Area : Line
        const strokes = {
          0: '#3C82DA',
          1: '#E6005F',
          2: '#FF7557',
          3: '#7856FF',
          4: '#B2596E',
        }
        return <ResponsiveContainer width='100%' height={450} key={index}>
          <ChartComponent data={chart.dataPoints} margin={{top: 30, right: 10, left: 10, bottom: 60}}>
            <XAxis dataKey={'day'} angle={-45} textAnchor='end'/>
            <YAxis allowDecimals={false} type='number'/>
            <CartesianGrid stroke="#353535"/>
            <Tooltip contentStyle={{backgroundColor: '#282828'}}/>
            {<Legend verticalAlign='top' iconSize={0} payload={[{ value: chart.name }]}/>}
            {chart.dataPointNames.map((dataPointName, index) =>
              <SeriesComponent key={index} name={dataPointName} dataKey={dataPointName} type='monotone' stroke={strokes[index]} fill={strokes[index]} fillOpacity={1} dot={false} stackId='1'/>
            )}
          </ChartComponent>
        </ResponsiveContainer>
      })}
    </Layout>
  )

  return (
    <div className='body-container'>
      {body}
    </div>
  )
}

export default AdminAnalytics
