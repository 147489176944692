import React from 'react'
import { Link } from 'react-router-dom'
import * as routes from '../../routes'

const AdminEditItemLink = ({item_name, item_type, item_id}) => {
  const itemName = item_name ? item_name : `${item_type} | ${item_id}`
  switch (item_type) {
    case 'BEAT':
      return <Link to={routes.dynamic(routes.adminBeatsEditPath, item_id)}>{itemName}</Link>
    case 'POST':
      return <Link to={routes.dynamic(routes.adminPostEditPath, item_id)}>{itemName}</Link>
    case 'PLAYLIST':
      return <Link to={routes.dynamic(routes.adminPlaylistEditPath, item_id)}>{itemName}</Link>
    default:
      return null
  }
}

export default AdminEditItemLink
